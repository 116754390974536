import axios from 'axios'
import railsRoutes from '../../rails-routes'

const state = {
}

const mutations = {
}

const getters = {
}

const actions = {
  fetchProductById({ commit }, payload) {
    return axios.get(
      railsRoutes.api_path({
        type: 'products',
        id: payload.productId
      })
    )
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
