import Vue from 'vue'
import axios from 'axios'
import { orderBy, findIndex } from 'lodash'
import railsRoutes from '../../../store/rails-routes'
import router from '../../../router'

// initial state
const state = {
  topics: [],
  modifiedAssetIds: [],
  topicsByParentId: {},
  topLevelAsset: {},
  draftMode: true
}

// EXAMPLE topics
//  [{id: 12, assets: [{id: 1111, assets: []}]}]

// getters
const getters = {
  modifiedAssets(state) {
    return state.topics.filter((x) => state.modifiedAssetIds.includes(x.id))
  },

  modifiedAssetIds(state) {
    return state.modifiedAssetIds
  },
  draftMode(state) {
    return state.draftMode
  },
  findTopicById: (state) => (id) => {
    return state.topics.filter((k) => k.id == id)[0]
  },
  topics(state) {
    return state.topics
  },
  topicsByParentId(state) {
    return state.topicsByParentId
  },
  topLevelAsset(state) {
    return state.topLevelAsset
  },
  subTopics(state) {
    return function(id) {
      if (state.topics.filter((k) => k.id == id && k.selected).length > 0) {
        return orderBy(state.topicsByParentId[id], ['order'], ['asc'])
      } else {
        return []
      }
    }
  },
  hierarchyPath(state, getters) {
    // This returns the root objects and children object in the selected path as an array
    return getters.nextHierarchyLevelPath(state.topLevelAsset).filter(Boolean)
  },
  nextHierarchyLevelPath(state, getters) {
    return function(selectedAsset) {
      if (selectedAsset) {
        var output = [selectedAsset]
        if (state.topicsByParentId.hasOwnProperty(selectedAsset.id)) {
          var nextLevel = getters.nextHierarchyLevelPath(
            state.topicsByParentId[selectedAsset.id].filter(
              (k) => k.selected
            )[0]
          )
          output = output.concat(nextLevel)
        }
        return output
      } else {
        return null
      }
    }
  },
  childrenOfParentId: (state) => (payload) => {
    if (state.topicsByParentId.hasOwnProperty(payload.id)) {
      const orderedChildren = orderBy(
        state.topicsByParentId[payload.id].filter((p) => !p.deleted),
        ['order'],
        ['asc']
      )
      return orderedChildren
    } else {
      return []
    }
  }
}
// actions
const actions = {
  selectAsset ({ dispatch }, payload) {
    dispatch('deselectTopics').then(() => {
      dispatch('selectTopic', { asset: payload.asset }).then(() => {
        dispatch('edit', payload.asset)
        if (router) {
          let path = `/topics/${payload.asset.id}/edit`
          if (path != router.currentRoute.path) {
            router.push({ path: path }).catch(err => {
              console.error(err)
            })
          }
        }
      })
    })
  },
  buildHierarchyFromPath ({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(railsRoutes.api_path({ type: 'topics' }), {
          params: {
            ids: payload.asset.path.split('.')
          }
        })
        .then(response => {
          if (response.data.records.length > 0) {
            for (let i in response.data.records) {
              commit('addTopic', { record: response.data.records[i] })
            }
          }
          resolve(response)
        }).catch(error => {
          console.error(error)
          reject(error)
        })
    })
  },
  selectTopic ({ state, dispatch }, payload) {
    // Make a copy of the original array of hierarchy assets so the dom isn't updated a ton of times every time we select something in the path
    let topicCopy = JSON.parse(JSON.stringify(state.topics))
    let id = payload.asset.id
    while (id !== null) {
      let breakOut = true
      for (const i in topicCopy) {
        if (topicCopy[i].id === id) {
          topicCopy.filter(x => x.parent_id == topicCopy[i].parent_id).forEach(element => {
            element.selected = false
          })

          topicCopy[i].selected = true
          id = topicCopy[i].parent_id

          breakOut = false
        }
      }
      if (breakOut) { // Failsafe to prevent an infinite loop
        break
      }
    }

    return dispatch('setTopics', { assets: topicCopy })
  },
  deselectTopics ({ state, dispatch }) {
    let topicCopy = JSON.parse(JSON.stringify(state.topics))

    for (const i in topicCopy) {
      if (topicCopy.hasOwnProperty(i)) {
        topicCopy[i].selected = false
      }
    }
    return dispatch('setTopics', { assets: topicCopy })
  },
  setTopics ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('initTopics', payload)

      commit('syncTopicsByParentId')
      resolve()
    }).catch(error => {
      console.error(error)
    })
  },
  addTopic ({ state, dispatch, commit }, payload) {
    // make sure the record doesn't exist before adding
    var assets = state.topics.filter(k => k.id == payload.record.id)
    if (assets.length === 0) {
      commit('insertTopic', payload)
    } else {
      payload.record.selected = assets[0].selected
      dispatch('updateTopic', { asset: payload.record })
    }
  },
  updateTopic({ commit }, payload) {
    commit('updateTopicOnly', payload)
    commit('updateTopicByParentIdOnly', payload)
  }
}

// mutations
const mutations = {
  addModifiedAssetId (state, payload) {
    state.modifiedAssetIds.push(payload.id)
  },
  toggleDraftMode (state) {
    state.draftMode = !state.draftMode
  },
  setDraftMode(state, payload) {
    state.draftMode = payload
  },
  initTopics (state, payload) {
    state.topics = []

    let assetCount = payload.assets.length
    state.topics.splice(assetCount)
    for (const i in payload.assets) {
      Vue.set(state.topics, i, payload.assets[i])
    }
  },
  resetTopics (state) {
    state.topics = []
  },
  updateTopicOnly (state, payload) {
    var index = findIndex(state.topics, { id: payload.asset.id })
    Vue.set(state.topics, index, payload.asset)
  },
  addTopicAssetAssociation (state, payload) {
    let selectedAsset = state.topics.filter(k => k.id == payload.id)[0]
    selectedAsset.asset_associations.push(payload.association)
  },
  insertTopic (state, payload) {
    let assetCount = state.topics.length
    state.topics.splice(assetCount + 1)
    Vue.set(state.topics, assetCount, payload.record)

    let parentId = payload.record.parent_id || 0
    if (!state.topicsByParentId.hasOwnProperty(parentId)) {
      state.topicsByParentId[parentId] = []
    }
    state.topicsByParentId[parentId].push(payload.record)
  },
  syncTopicsByParentId (state) {
    state.topicsByParentId = {}

    for (const key in state.topics) {
      if (state.topics.hasOwnProperty(key)) {
        const element = state.topics[key]
        let parentId = element.parent_id || 0

        if (!state.topicsByParentId.hasOwnProperty(parentId)) {
          state.topicsByParentId[parentId] = []
        }

        let assetCount = state.topicsByParentId[parentId].length
        state.topicsByParentId[parentId].splice(assetCount + 1)
        Vue.set(state.topicsByParentId[parentId], assetCount, element)
      }
    }
  },
  setTopLevelAsset (state, payload) {
    payload.asset.selected = true // Always select the root asset
    state.topLevelAsset = payload.asset
    state.modifiedAssetIds = [payload.asset.id]
  },
  removeTopic (state, payload) {
    // make sure the record doesn't exist before adding
    if (
      state.topics.filter(k => k.id == payload.record.id).length > 0
    ) {
      state.topics.splice(
        state.topics.findIndex(x => x.id == payload.record.id),
        1
      )
      let children = state.topicsByParentId[payload.record.parent_id]
      let index = children.findIndex(c => c.id === payload.record.id)
      children.splice(index, 1)
      state.topicsByParentId[payload.record.parent_id] = children
    }
  },
  updateTopicByParentIdOnly (state, payload) {
    if (payload.asset.parent_id == null) {
      return false // Root topic doesn't have siblings so no need to track it like this
    }
    if (state.topicsByParentId && state.topicsByParentId.hasOwnProperty(payload.asset.parent_id)) {
      let children = state.topicsByParentId[payload.asset.parent_id]
      let child = children.filter(c => c.id === payload.asset.id)[0]
      let index = children.indexOf(child)
      child = payload.asset
      Vue.set(children, index, child)
      Vue.set(state.topicsByParentId, payload.asset.parent_id, children)
    } else {
      console.error('Error with updating topic')
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
