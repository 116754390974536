import axios from 'axios'
import railsRoutes from '../../../store/rails-routes'

const state = {
  assignment: null,
  assignmentReport: null,
  nextAssignmentId: null
}

const mutations = {
  setAssignment (state, payload) {
    state.assignment = payload
  },
  setAssignmentReport(state, payload) {
    const getRandomHexColor = () => {
      let hex = "0123456789ABCDEF"
      let color = "#"
      for (var i = 1; i <= 6; i++) {
        color += hex[Math.floor(Math.random() * 16)]
      }
      return color
    }
    payload.records.forEach(a => a.hexColor = getRandomHexColor())
    state.assignmentReport = payload
  },
  setNextAssignmentId(state, payload) {
    state.nextAssignmentId = payload
  },
  createChangeRequest (state, payload) {
    state.assignment.asset_change_requests.push(payload)
  },
  changeAssignmentState (state, payload) {
    state.assignment.state = payload
  },
  setChangeRequestState (state, payload) {
    const cr = this.getters.changeRequest(
      payload.id,
      payload.type,
      payload.column
    )
    cr.state = payload.changeRequestState
  }
}

const getters = {
  assignment (state) {
    return state.assignment
  },
  assignmentReport (state) {
    return state.assignmentReport
  },
  nextAssignmentId(state) {
    return state.nextAssignmentId
  },
  changeRequest: (state) => (id, type, column) => {
    return state.assignment.asset_change_requests.filter(r => {
      return r.asset_id === id && r.asset_type == type && r.asset_column === column
    })[0]
  }
}

const actions = {
  getAssignment ({ commit }, id) {
    return axios.get(railsRoutes.api_path({ type: 'asset_assignments', id: id }))
      .then((response) => {
        commit('setAssignment', response.data.records[0])
        commit('setNextAssignmentId', response.data.meta.next_assignment_id)
      })
  },
  getAssignmentReport({ commit }, payload) {
    let queryParams = '?'
    if (payload.days) {
      queryParams += `days=${payload.days}`
    }
    if (payload.app_id && payload.app_id !== '') {
      queryParams += `&app_id=${payload.app_id}`
    }
    return axios.get(railsRoutes.api_path({ type: 'asset_assignments', action: 'report' }) + queryParams)
      .then((response) => {
        commit('setAssignmentReport', response.data)
      })
  },
  updateAssignment ({ commit, state }) {
    return axios.put(railsRoutes.api_path({
      type: 'asset_assignments', id: state.assignment.id
    }),
    { asset_assignment: state.assignment })
      .then((response) => {
        commit('setAssignment', response.data.records[0])
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
