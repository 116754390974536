import axios from 'axios'
import railsRoutes from '../../store/rails-routes'

const state = {
  roles: null,
  themes: null,
  tags: null
}

const getters = {
  roles (state) {
    return state.roles
  },
  themes (state) {
    return state.themes
  },
  tags (state) {
    return state.tags
  }
}

const actions = {
  getRoles ({ commit }) {
    return axios.get(railsRoutes.api_path({ type: 'roles' })).then(response => {
      commit('setRoles', response.data.records)
    })
  },
  addRole ({ state }) {
    let roleName = 'New Role ' + (state.roles.length + 1)
    return axios.post(railsRoutes.api_path({ type: 'roles' }), { role: { name: roleName } })
  },
  getThemes ({ commit }) {
    return axios.get(railsRoutes.api_path({ type: 'themes' })).then(response => {
      commit('setThemes', response.data.records)
    })
  },
  addTheme () {
    return axios.post(railsRoutes.api_path({ type: 'themes' }))
  },
  copyTheme ({ commit }, id) {
    return axios.post(railsRoutes.api_path({ type: 'themes', id: id, action: 'duplicate' })).then((response) => {
      commit('setThemes', response.data.records)
    })
  },
  getTags ({ commit }) {
    return axios.get(railsRoutes.api_path({ type: 'tags' })).then(response => {
      commit('setTags', response.data.records)
    })
  }
}

const mutations = {
  setRoles (state, payload) {
    state.roles = payload.sort((a, b) => {
      if (a.name > b.name) { return 1 }
      if (a.name < b.name) { return -1 }
      return 0
    })
  },
  setThemes (state, payload) {
    state.themes = payload.sort((a, b) => {
      if (a.name > b.name) { return 1 }
      if (a.name < b.name) { return -1 }
      return 0
    })
  },
  setTags (state, payload) {
    state.tags = payload.sort((a, b) => {
      if (a.name > b.name) { return 1 }
      if (a.name < b.name) { return -1 }
      return 0
    })
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
