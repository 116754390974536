import skin from 'tinymce-light-skin'
import tinymce from 'tinymce/tinymce' /* eslint-disable-line */
import 'tinymce/themes/modern/theme'

import 'tinymce/plugins/image'
import 'tinymce/plugins/table'
import 'tinymce/plugins/code'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/autoresize'
import '@/assets/stylesheets/cms/_app.scss'
import './constants'
import Vue from 'vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal'
import { sync } from 'vuex-router-sync'
import axios from 'axios'
import VTooltip from 'v-tooltip'
import VueCookies from 'vue-cookies'
import App from './components/App.vue'
import vueSmoothScroll from 'vue2-smooth-scroll'
import NProgress from 'nprogress'
import FunctionalCalendar from 'vue-functional-calendar'
import ErrorMessage from './components/assets/shared/ErrorMessage'
import VueLodash from 'vue-lodash'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import ActionCableVue from 'actioncable-vue'
import Notifications from 'vue-notification'
import VueCompositionAPI from '@vue/composition-api'
import Toggle from '@vueform/toggle/dist/toggle.vue2.js'
import './filters'
import './plugins/date'
import './plugins/axios'
import VueConfirmDialog from 'vue-confirm-dialog'

const npOptions = {
  trickle: true,
  minimum: 0.2,
  trickleSpeed: 900,
  showSpinner: false,
  trickleRate: 0.02,
  latencyThreshold: 300,
  router: true,
  http: true,
  easing: 'linear',
  speed: 600
}

NProgress.configure(npOptions)
Vue.use(VueLodash, { lodash: { orderBy, sortBy, last, isEqual } })
Vue.use(VModal)
Vue.use(VueCookies)
Vue.use(vueSmoothScroll)
Vue.use(VTooltip)
Vue.use(VueCompositionAPI)
Vue.component('toggle', Toggle)
Vue.use(FunctionalCalendar, {
  dayNames: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
})
Vue.use(Notifications)
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'all',
  connectionUrl: process.env.VUE_APP_WEBSOCKET_URL,
  connectImmediately: true
})

Vue.component('error-message', ErrorMessage)
Vue.use(VueConfirmDialog)
Vue.component("vue-confirm-dialog", VueConfirmDialog.default)

// AXIOS AND PROGRESS BAR CODE
const calculatePercentage = (loaded, total) => (Math.floor(loaded * 1.0) / total)
axios.defaults.onDownloadProgress = e => {
  const percentage = calculatePercentage(e.loaded, e.total)
  NProgress.set(percentage)
}
// Addins Axios default for dev
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log('DEVELOPMENT CMS')
  const port = 3000
  axios.defaults.baseURL = location.protocol + '//' + location.hostname + ':' + port
} else {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
}

skin.use()
const unsync = sync(store, router) /* eslint-disable-line */

/* eslint-disable */
const admin_user_email = window.$cookies.get('authentication_email')
const admin_user_token = window.$cookies.get('authentication_token')

import * as Sentry from '@sentry/browser'
import * as SentryIntegrations from '@sentry/integrations';

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new SentryIntegrations.Vue({ Vue, attachProps: true })]
})

Sentry.configureScope(scope => {
  scope.setUser({ email: admin_user_email })
})

document.addEventListener('DOMContentLoaded', () => {
  axios.defaults.headers.common['X-AdminUser-Email'] = admin_user_email
  axios.defaults.headers.common['X-AdminUser-Token'] = admin_user_token
  new Vue({
    el: '#cms',
    router,
    store,
    render: h => h(App)
  })
})
