<template>
  <div
    v-if="currentUser.permissions && currentUser.permissions.can_read_new_cms"
    class="row expanded"
  >
    <div class="medium-12 column page-content">
      <div id="cms-inner">
        <router-view :key="$route.fullPath" />
        <refresh-notice
          :show-notice="showNotice"
          :build-hash-changed="buildHashChanged"
          @reloadApp="reloadApp"
          @closeNotice="closeNotice"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { refreshUser } from '../mixins/refresh-user'
import RefreshNotice from './RefreshNotice'

export default {
  components: {
    RefreshNotice
  },
  mixins: [refreshUser],
  data () {
    return {
      showNotice: true
    }
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },
  mounted () {
    this.$cable.subscribe({
      channel: 'CmsAdminUserNotificationsChannel'
    })
    this.initVersionCheck()
  },
  created () {
  },
  methods: {
    ...mapMutations([
      'addNotifications',
      'setUnreadNotificationsCount'
    ]),
    closeNotice () {
      this.showNotice = !this.showNotice
    }
  },
  channels: {
    CmsAdminUserNotificationsChannel: {
      connected () {
        console.log('connected to channel')
      },
      rejected () {
        console.log('rejected from channel')
      },
      received (data) {
        console.log('received data from channel', data)
        if (data.cms_notification) {
          this.addNotifications([data.cms_notification])
          this.setUnreadNotificationsCount(data.unread_count)
        }
        if (data.title) {
          this.$notify({
            title: data.title,
            type: data.type,
            text: data.text,
            duration: data.duration
          })
        }
      },
      disconnected () {
        console.log('disconnected from channel')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#cms-inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
