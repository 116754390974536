import axios from 'axios'
import railsRoutes from '../../rails-routes'

const state = {
  userClassifications: []
}

const mutations = {
  setUserClassifications (state, payload) {
    state.userClassifications = payload
  }
}

const getters = {
  userClassifications (state) {
    return state.userClassifications
  }
}

const actions = {
  fetchUserClassifications ({ commit, state }, payload) {
    return axios
      .get(railsRoutes.api_path({ type: 'user_classifications' }) + `?app_id=${payload.appId}`)
      .then((response) => {
        commit('setUserClassifications', response.data.records)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
