import Vue from 'vue'
import orderBy from 'lodash/orderBy'
const state = {
  errors: {},
  cmsUsers: [],
  appCategories: [],
  discussionMode: false,
  orderBoxReordering: false
}

const mutations = {
  setOrderBoxReordering (state, payload) {
    state.orderBoxReordering = payload
  },
  setErrors (state, payload) {
    state.errors = payload.errors
  },
  resetErrors (state) {
    state.errors = {}
  },
  setCmsUsers (state, payload) {
    state.cmsUsers = orderBy(payload.cmsUsers, ['full_name'], ['asc'])
  },
  setAppCategories (state, payload) {
    state.appCategories[payload.appId] = payload.categories
  },
  setDiscussionMode (state, payload) {
    state.discussionMode = payload.discussionMode
  }
}

const getters = {
  discussionMode (state) {
    return state.discussionMode
  },
  orderBoxReordering (state) {
    return state.orderBoxReordering
  },
  cmsUsers (state) {
    return state.cmsUsers
  },
  errors (state) {
    return state.errors
  },
  apps (state, getters, rootState, rootGetters) {
    if (getters.isAttachmentRoute) {
      if (rootGetters.asset && rootGetters.asset.apps) {
        return rootGetters.asset.apps
      }
    } else if (getters.isTopicRoute) {
      if (rootGetters.topLevelAsset && rootGetters.topLevelAsset.product_associations) {
        return rootGetters.topLevelAsset.root_apps
      }
    } else if (getters.isItemRoute) {
      if (rootState.asset) {
        return rootState.asset.apps
      }
    }
    return []
  },
  appCategories (state) {
    return state.appCategories
  }
}

const actions = {
  copyToClipboard ({ dispatch, commit }, payload) {
    const nodeToCopy = document.querySelector(payload.selector)
    nodeToCopy.setAttribute('type', 'text')
    nodeToCopy.select()
    document.execCommand('copy')
    nodeToCopy.setAttribute('type', 'hidden')

    Vue.prototype.$notify({ title: 'Copied to Clipboard', type: 'success' })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
