<template>
  <div>
    <a
      v-if="hasAccess"
      href="#create-new"
      class="create-button hlt-button navigation-create"
      @click.exact.prevent="createAsset()"
    >
      Create
    </a>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../store/rails-routes'
import axios from 'axios'
export default {
  data () {
    return {
      railsRoutes: railsRoutes,
      newTopicParams: { url: railsRoutes.api_path({ type: 'topics' }) },
      newAttachmentParams: { url: railsRoutes.api_path({ type: 'attachments' }) },
      newGroupingParams: { url: railsRoutes.api_path({ type: 'groupings' }) },
      newLearningModuleParams: { url: railsRoutes.api_path({ type: 'learning_modules' }) },
      newContentSourceParams: { url: railsRoutes.api_path({ type: 'content_sources' }) }
    }
  },
  created () {
    if (this.$route && this.$route.hash == '#create-new') {
      this.createAsset()
    }
  },
  computed: {
    ...mapGetters([
      'canWriteToAsset',
      'currentUser'
    ]),
    hasAccess () {
      return (this.canWriteToAsset && !this.$router.isRoute('discussion') && this.$route?.path !== '/' && !this.$router.isRoute('app_hierarchy'))
    }
  },
  methods: {
    async createAsset () {
      switch(this.$router.pluralName()) {
        case 'topics':
          this.createTopic()
          break
        case 'attachments':
          this.createAttachment()
          break
        case 'items':
          this.$modal.show('create-item-modal')
          break
        case 'groupings':
          this.createGrouping()
          break
        case 'content-sources':
          this.createContentSource()
          break
        case 'learning_modules':
          this.createLearningModule()
          break
        case 'decks':
          this.createDeck()
          break
        case 'roles':
          this.createRole()
          break
        case 'themes':
          this.createTheme()
          break
        case 'tags':
          this.createTag()
          break
        case 'catalog_pages':
          this.$modal.show('create-catalog-page-modal')
          break
        case 'catalogs':
          this.$modal.show('create-catalog-modal')  
          break
        case 'admin-lms-users':
          this.$modal.show('create-lms-user-modal')
          break
      }
    },
    createTopic () {
      this.create(this.newTopicParams).then(response => {
        this.resetTopics()
        this.resetAsset()
        let record = response.data.records[0]
        this.$router.push({ path: railsRoutes.cms_path({ type: 'topics', id: record.id }) })
        this.$notify({ title: 'Topic Created', type: 'success' })
      }).catch(error => {
        console.error('ERROR', error)
        this.$notify({ title: 'Error Creating Topic', type: 'error' })
      })
    },
    createAttachment () {
      this.create(this.newAttachmentParams).then(response => {
        let record = response.data.records[0]
        this.$router.push({ path: railsRoutes.cms_path({ type: 'attachments', id: record.id }) })
        this.$notify({ title: 'Attachment Created', type: 'success' })
      }).catch(error => {
        console.error('ERROR', error)
        this.$notify({ title: 'Error Creating Attachment', type: 'error' })
      })
    },
    createGrouping () {
      this.create(this.newGroupingParams).then(response => {
        let record = response.data.records[0]
        this.$router.push({ path: railsRoutes.cms_path({ type: 'groupings', id: record.id }) })
        this.$notify({ title: 'Grouping Created', type: 'success' })
      }).catch(error => {
        console.error('ERROR', error)
        this.$notify({ title: 'Error Creating Grouping', type: 'error' })
      })
    },
    createContentSource () {
      this.create(this.newContentSourceParams).then(() => {
        this.$notify({ title: 'Content Source Created', type: 'success' })
        this.resetSearchResults()
        this.searching()
      }).catch(error => {
        console.error('ERROR', error)
        this.$notify({ title: 'Error Creating Content Source', type: 'error' })
      })
    },
    createLearningModule () {
      this.create(this.newLearningModuleParams).then(response => {
        let record = response.data.records[0]
        this.$router.push({ path: railsRoutes.cms_path({ type: 'learning_modules', id: record.id }) })
        this.$notify({ title: 'Module Created', type: 'success' })
      }).catch(error => {
        console.error('ERROR', error)
        this.$notify({ title: 'Error Creating Module', type: 'error' })
      })
    },
    createDeck () {
      if(this.currentUser.permissions.can_write_decks) {
        this.create({ url: railsRoutes.api_path({ type: 'decks' }) }).then((result) => {
          this.$router.push(railsRoutes.cms_path({ type: 'decks', id: result.data.records[0].id }))
          this.$notify({ title: 'Deck Created', type: 'success' })
        }).catch(error => {
          console.error(error)
          this.$notify({ title: 'Error Creating Deck', type: 'error' })
        })
      }
    },
    createRole () {
      if (this.currentUser.permissions.can_write_roles) {
        this.addRole().then((response) => {
          this.$router.push(railsRoutes.cms_path({ type: 'roles', id: response.data.records[0].id }))
          this.$notify({ title: 'Role Created', type: 'success' })
        }).catch(error => {
          console.error(error)
          this.$notify({ title: 'Error Creating Role', type: 'error' })
        })
      }
    },
    createTheme () {
      if(this.currentUser.permissions.can_write_themes) {
       this.addTheme().then((response) => {
          this.$router.push(railsRoutes.cms_path({ type: 'themes', id: response.data.records[0].id }))
          this.$notify({ title: 'Theme Created', type: 'success' })
        }).catch(error => {
          console.error(error)
          this.$notify({ title: 'Error Creating Theme', type: 'error' })
        })
      }
    },
    async createTag () {
      if (this.currentUser.permissions.can_write_tags) {
        await this.addTag()
        await this.$notify({ title: 'Tag Created', type: 'success' })
        this.resetSearchResults()
        this.searching()
      }
    },
    addTag () {
      return axios.post(railsRoutes.api_path({ type: 'tags' })).then((result) => {
        this.edit(result.data.records[0])
        this.$modal.show('edit-tag-modal')
      }).catch(error => {
        console.error('ERROR', error)
        this.$notify({ title: 'Error Creating Tag', type: 'error' })
      })
    },
    ...mapActions([
      'edit',
      'create',
      'searching',
      'addRole',
      'addTheme'
    ]),
    ...mapMutations([
      'resetTopics',
      'resetSearchResults',
      'resetAsset'
    ])
  }
}
</script>
