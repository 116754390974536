<template>
  <div
    v-if="buildHashChanged && $root.env !== 'development'"
    :class="[{ 'hide-notification': showNotice === false }, 'refresh-container']"
  >
    <div class="notification-header">
      <a
        class="close-refresh-notice"
        @click="closeNotice"
      >
        <span aria-hidden="true">
          <x-svg class="close-svg" />
        </span>
      </a>
    </div>
    <div class="notification-body">
      <div class="notification-button">
        <h5 class="text-center">
          **Notice**
        </h5>
        <p class="text-center font12">
          An update is available. Please save all current work and click update below. You can also accept these updates by refreshing your browser at any time.
        </p>
        <p class="text-center">
          <span class="font10">Not updating may result in errors.</span>
        </p>
      </div>
      <div class="refresh-button text-center">
        <button
          class="hlt-button primary"
          @click="reloadApp"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import XSvg from '@/assets/images/icons/x.svg'

export default {
  components: {
    XSvg
  },
  props: {
    buildHashChanged: {
      type: Boolean,
      required: true
    },
    showNotice: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    reloadApp () {
      this.$emit('reloadApp')
    },
    closeNotice () {
      this.$emit('closeNotice')
    }
  }
}
</script>

<style lang="scss" scoped>
.refresh-container {
  width: 20%;
  position: fixed;
  top: 90px;
  right: 30px;
  background-color: $white;
  padding: 40px 15px  30px 15px;
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  z-index: 999;
}
.close-refresh-notice {
  position: absolute;
  padding: 3px;
  right: 5px;
  top: 5px;
  border: none;
}
.hide-notification {
  display: none;
}
</style>
