import axios from 'axios'
import railsRoutes from '../../store/rails-routes'
import orderBy from 'lodash/orderBy'

const state = {
  thread: []
}

const mutations = {
  setThread (state, payload) {
    state.thread = payload
  }
}

const getters = {
  thread (state) {
    return orderBy(state.thread, ['created_at'], ['asc'])
  }
}

const actions = {
  getThread ({ commit }, id) {
    return axios.get(railsRoutes.api_path({ type: 'discussions', id: id }))
      .then((response) => {
        commit('setThread', response.data.records)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
