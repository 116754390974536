/* eslint-disable no-console */
import axios from 'axios'

export const refreshUser = {
  data () {
    return {
      currentBuildHash: 'POST_BUILD_ENTERS_HASH_HERE',
      buildHashChanged: false,
      newBuildHash: ''
    }
  },
  methods: {
    // Set interval to every 2 hours
    initVersionCheck (url = `${process.env.VUE_APP_CMS_URL}/version.json`, frequency = 1000 * 60 * 60 * 2) {
      setInterval(() => {
        this.checkVersion(url)
      }, frequency)
    },
    async checkVersion (url) {
      try {
        const fileResponse = await axios.get(`${url}?t=${new Date().getTime()}`)
        this.newBuildHash = fileResponse.data.hash
        this.buildHashChanged = this.hasBuildHashChanged()
      } catch (error) {
        if (!error.response) {
          this.errorStatus = 'Error: Network Error'
        } else {
          this.errorStatus = error.response.data.message
        }
      }
    },
    hasBuildHashChanged () {
      return this.currentBuildHash && (this.newBuildHash !== this.currentBuildHash)
    },
    reloadApp () {
      this.currentBuildHash = this.newBuildHash
      window.location.reload()
    }
  }
}
