export default {
  methods: {
    prettyDate (timestamp) {
      if (this.$date && timestamp) {
        const m = this.$date(timestamp * 1000)
        return m.format('llll')
      }
      return 'Unknown'
    },
    timeAgo(timestamp) {
      return this.$date(timestamp * 1000).fromNow()
    }
  }
}
