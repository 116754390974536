<template>
  <div class="password-form">
    <div v-if="showForm">
      <h4 class="hlt-header h4 weight-600">Email Password Reset Instructions</h4>
      <div class="email">
        <label for="email">Email</label>
        <input
          v-model="email"
          type="text"
          placeholder="email address"
          class="hlt-input"
        >
      </div>
      <error-message
        v-if="errors"
        message="Not a valid email address"
      />
      <div class="submit-button">
        <button @click.stop="send" class="hlt-button secondary">
          Send Instructions
        </button>
      </div>
    </div>
    <div v-else>
      <h4 class="hlt-header h4 weight-600">Password reset instructions sent</h4>
      <p>Please check your email for instructions on resetting your password</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import railsRoutes from '../../store/rails-routes'

export default {
  data () {
    return {
      email: null,
      errors: null,
      showForm: true
    }
  },
  methods: {
    send () {
      if (this.email) {
        const data = {
          email: this.email
        }
        axios.put(railsRoutes.api_path({ type: 'admin_users', action: 'send_password_reset_instructions' }), data).then(response => {
          this.showForm = false
        }).catch(error => {
          this.errors = true
          console.error(error)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.password-form {
  margin-top: 30px;
  padding: 30px;
  background: white;
  border: 1px solid $space-gray-20;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  h4 {
    color: $space-gray;
  }

  .email {
    margin-bottom: 10px;
  }

  label {
    text-align: left;
    font-weight: 700;
    padding-bottom: 5px;
    color: $space-gray;
  }
}
</style>
