<template>
  <div class="lower-nav">
    <div class="hlt-logo">
      <logo-svg />
    </div>
    <ul class="main-nav-list">
      <li :class="[{'active': activeNav('dashboard')}, 'dashboard']">
        <router-link
          :to="{ name: 'dashboard' }"
        >
          <div>Dashboard</div>
        </router-link>
      </li>

      <li
        v-if="currentUser.permissions.can_read_flashcards"
        :class="[{'active': activeNav('assets')}, 'items']"
      >
        <router-link
          :to="{ name: 'items' }"
        >
          <div>Assets</div>
        </router-link>
      </li>

      <li
        v-else-if="currentUser.permissions.can_read_topics"
        :class="[{'active': activeNav('assets')}, 'topics']"
      >
        <router-link
          :to="{ name: 'topics' }"
        >
          <div>Assets</div>
        </router-link>
      </li>

      <li
        v-else-if="currentUser.permissions.can_read_attachments"
        :class="[{'active': activeNav('assets')}, 'attachments']"
      >
        <router-link
          :to="{ name: 'attachments' }"
        >
          <div>Assets</div>
        </router-link>
      </li>

      <li
        v-else-if="currentUser.permissions.can_read_groupings"
        :class="[{'active': activeNav('assets')}, 'groupings']"
      >
        <router-link
          :to="{ name: 'groupings' }"
        >
          <div>Assets</div>
        </router-link>
      </li>

      <li
        v-if="currentUser.permissions.can_read_decks"
        :class="[{'active': activeNav('app')}, 'decks']"
      >
        <router-link
          :to="{ name: 'decks' }"
        >
          <div>Structure</div>
        </router-link>
      </li>

      <li
        v-else-if="currentUser.permissions.can_read_catalogs"
        :class="[{'active': activeNav('app')}, 'catalogs']"
      >
        <router-link
          :to="{ name: 'catalogs' }"
        >
          <div>Structure</div>
        </router-link>
      </li>


      <li
        v-if="currentUser.permissions.can_read_discussions"
        :class="[{'active': activeNav('publishing')}, 'publishing']"
      >
        <router-link
          :to="{ name: 'discussions' }"
        >
          <div>Publishing</div>
        </router-link>
      </li>
      <li
        v-else-if="canAccessAssignments"
        :class="[{'active': activeNav('publishing')}, 'publishing']"
      >
        <router-link
          :to="{ name: 'asset_assignments' }"
        >
          <div>Publishing</div>
        </router-link>
      </li>
      <!-- <li
        v-if="currentUser.is_admin"
        :class="[{'active': activeNav('history')}, 'history']"
      >
        <router-link
          :to="{ name: 'history' }"
        >
          <div>History</div>
        </router-link>
      </li> -->

      <li
        v-if="currentUser.permissions.is_a_asset_assigner"
        :class="[{'active': activeNav('reports')}, 'reports']"
      >
        <router-link
          :to="{ name: 'assignment_reports' }"
        >
          <div>Reports</div>
        </router-link>
      </li>

      <li
        v-if="currentUser.permissions.can_read_roles"
        :class="[{'active': activeNav('settings')}, 'roles']"
      >
        <router-link
          :to="{ name: 'roles' }"
        >
          <div>Settings</div>
        </router-link>
      </li>

      <li
        v-else-if="currentUser.permissions.can_read_themes"
        :class="[{'active': activeNav('settings')}, 'themes']"
      >
        <router-link
          :to="{ name: 'themes' }"
        >
          <div>Settings</div>
        </router-link>
      </li>
      <li
        v-if="currentUser.permissions.can_write_admin_users"
        :class="[{'active': activeNav('admin')}, 'admin']"
      >
        <router-link
          :to="{ name: 'admin-lms-users' }"
        >
          <div>Admin</div>
        </router-link>
      </li>
    </ul>
    <div class="right-nav">
      <div class="powered-by">Powered by HLT Mastery</div>
      <create-button />
      <notification-feed />
      <profile />
    </div>
    <create-item-modal />
    <create-catalog-page-modal />
    <create-catalog-modal />
    <create-lms-user-modal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NotificationFeed from './shared/NotificationFeed.vue'
import CreateButton from './shared/CreateButton.vue'
import CreateItemModal from './assets/items/CreateModal.vue'
import CreateLmsUserModal from './admin/lms-users/CreateModal.vue'
import CreateCatalogPageModal from './app_manager/catalog_pages/CreateModal.vue'
import CreateCatalogModal from './app_manager/catalogs/CreateModal.vue'
import Profile from './shared/Profile.vue'
import LogoSvg from '@/assets/images/icons/icon-hlt.svg'

export default {
  components: {
    CreateButton,
    NotificationFeed,
    CreateItemModal,
    CreateCatalogPageModal,
    CreateCatalogModal,
    CreateLmsUserModal,
    Profile,
    LogoSvg
  },
  data () {
    return {
      routes: {
        dashboard: ['dashboard'],
        assets: ['topic',
          'topics',
          'attachments',
          'attachment',
          'items',
          'item',
          'groupings',
          'grouping'
        ],
        settings: ['role', 'roles', 'theme', 'themes'],
        history: ['history'],
        reports: ['assignment_reports'],
        publishing: ['discussions', 'asset_assignments'],
        app: ['decks', 'deck', 'app-hierarchy'],
        admin: ['lms-users']
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'canAccessAssignments'
    ])
  },
  methods: {
    activeNav (name) {
      return this.routes[name].includes(this.$route.name)
    }
  }
}
</script>
<style lang="scss">
.lower-nav {
  background-color: $blue-2;
  display: flex;
  max-height: 50px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  .hlt-logo {
    padding: 5px 20px;
    img {
      height: 24px;
    }
  }
  .main-nav-list {
//    flex: 7;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    line-height: 1;
    li {
      &.active {
        box-shadow: inset 0 -2px 0 0 $sky-blue;
        a {
          color: $white-2;
          font-weight: 700;
          text-align: center;
        }
      }
      a {
        padding: 14px 24px 17px;
        display: block;
        font-size: 14px;
        font-weight: 600;
        color: $space-gray;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0.03em;
        transition: .3s;
        &:hover {
          color: $white-2;
        }
      }
    }
  }
  .right-nav {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    align-items: center;
    .powered-by {
      font-size: 12px;
      color: $space-gray;
      margin-right: 20px;
    }
  }
}
</style>
