<template>
  <div class="error-message" v-html="message" />
</template>

<script>
export default {
  props: ['message']
}
</script>
<style lang="scss">
  .error {
    border: 1px solid $red-1 !important;
  }

  .error-message {
    color: $red-1 !important;
    font-size: 12px;

  }
</style>
