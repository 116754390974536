<template>
  <div class="password-form">
    <h4 class="hlt-header h4 weight-600">Reset Password</h4>
    <p>{{ resetPasswordText }}</p>
    <div class="password">
      <label for="password">New Password</label>
      <password
        v-model="password"
        :toggle="true"
        :secureLength="12"
        @score="checkStrength"
      />
    </div>
    <div class="password-confirmation">
      <label for="password-confirmation">New Password Confirmation</label>
      <input
        v-model="passwordConfirmation"
        type="password"
        placeholder="type in your lovely password"
        class="hlt-input"
      >
    </div>
    <error-message
      v-if="errors"
      :message="formattedErrors"
    />
    <div>
      <button
        @click.stop="changePassword"
        :disabled='disabled'
        :class="['hlt-button', 'secondary', 'change-password-button', { 'disabled': disabled }]"
      >
        Change Password
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import railsRoutes from '../../store/rails-routes'
import Password from 'vue-password-strength-meter'
import { mapActions } from 'vuex'

export default {
  components: {
    Password
  },
  data () {
    return {
      password: null,
      passwordConfirmation: null,
      errors: null,
      disabled: true
    }
  },
  computed: {
    resetPasswordText () {
      const text = "Please select a new password."
      if (this.$route.query.token) {
        return text
      } else {
        return `Your password has expired. ${text}`
      }
    },
    formattedErrors () {
      let output = ""
      if(this.errors.password) {
        this.errors.password.forEach(x => {
          output += `<li>${x}</li>`
        })
      } else {
        output = `<li>${this.errors}</li>`
      }
      return `<ul>${output}</ul>`
    }
  },
  methods: {
    ...mapActions([
      'signOut'
    ]),
    checkStrength (score) {
      if(score == 4) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    changePassword () {
      if (this.password && this.passwordConfirmation) {
        const data = {
          admin_user: {
            password: this.password,
            password_confirmation: this.password_confirmation,
            password_reset_token: this.$route.query.token || this.$route.query.password_reset_with_token // Use either cms token name or AA token name 
          }
        }
        let url = null
        if (this.$route.query.token) {
          url = railsRoutes.api_path({ type: 'admin_users', action: 'reset_password_with_token' })
        } else {
          url = railsRoutes.api_path({ type: 'admin_users' })
        }
        axios.put(url, data).then(response => {
          this.$notify({
            title: 'Password reset successful',
            text: 'Please login with your new password',
            type: 'success'
          })
          this.$emit('passwordReset')
          this.signOut()
        }).catch(error => {
          console.error(error.response)
          this.errors = error.response.data.errors
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.password-form {
  margin-top: 30px;
  padding: 30px;
  background: white;
  border: 1px solid $space-gray-20;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 500px;

  h4, p {
    color: $space-gray;
  }

  .password-confirmation {
    margin-bottom: 10px;
  }

  label {
    text-align: left;
    font-weight: 700;
    padding-bottom: 5px;
    color: $space-gray;
  }
}
</style>


<style lang="scss">
.password-form {
  .Password {
    max-width: unset;
    margin: unset;
  }
}
</style>