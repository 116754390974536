<template>
<div class="hlt-nav-container">
  <main-nav />
  <div class="sub-nav-container">
    <div class="list-elements">
      <slot name="listItems" />
    </div>
  </div>
</div>
</template>

<script>
import MainNav from '../MainNav'
export default {
  name: 'NavContainer',
  components: {
    MainNav
  }
}
</script>

<style lang="scss" scoped>
.sub-nav-container {
  height: 34px;
  max-height: 34px;
  background-color: $space-black;
  display: flex;
  padding: 5px 20px;
  align-items: center;
  width: 100%;
  z-index: 9999;
  .title {
    font-size: 22px;
    font-weight: 800;
  }

  .list-elements {
    flex: 5;
    padding: 0 30px;
    li {
      font-size: 11px;
      font-weight: 400;
      letter-spacing: .03em;
      &.top-active {
        box-shadow: inset 0 -2px 0 0 $sky-blue;
        a {
          color: $white-2;
          font-weight: 600;
        }
      }

      a {
        color: $space-gray;
        display: block;
        text-align: center;

        &:hover {
          color: $white-2;
          transition: .3s;
        }
      }
    }
  }
  .right-nav {
    display: flex;
    justify-content: space-between;
  }
}

</style>
