import axios from 'axios'

// initial state
const state = {
  asset: {},
  assetType: '',
  deleteAsset: false,
  deletableAsset: {}
}

// getters
const getters = {
  asset (state) {
    return state.asset
  },
  assetType (state) {
    return state.assetType
  },
  apiAssetType (state) {
    let apiAssetType = state.assetType
    if (apiAssetType == 'item') {
      apiAssetType = 'flashcard'
    }
    return apiAssetType
  },
  deleteAsset (state) {
    return state.deleteAsset
  },
  deletableAsset (state) {
    return state.deletableAsset
  }
}

// actions
const actions = {
  create ({ commit, state }, payload) {
    let data = new FormData()
    for (let key in payload.asset) {
      data.append(state.assetType + '[' + key + ']', payload.asset[key])
    }
    return axios.post(payload.url + '.json', data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  newAsset ({ commit, state }, record) {
    // Maybe this needs a list of attributes from somewhere
    //
    commit('setAsset', { asset: {} })
  },
  edit ({ commit, state }, record) {
    commit('setAsset', { asset: record })
  },
  update ({ state }, payload) {
    let assetType = `${state.assetType}`
    let params = {}
    params[assetType] = payload.asset
    return axios.put(payload.url + '/' + payload.asset.id + '.json', params)
  },
  attachmentUpdate ({ commit, state }, payload) {
    // MAKE API CALL
    let data = new FormData()
    for (let key in payload.asset) {
      if (Array.isArray(payload.asset[key])) {
        if (payload.asset[key].length === 0) {
          data.append(
            state.assetType + '[' + key + '][0][fakeKey]',
            'fakeValue'
          )
        }

        for (let i in payload.asset[key]) {
          if (payload.asset[key][i] &&
            (payload.asset[key][i].constructor == Object)) {
            // If not primitive
            for (let j in payload.asset[key][i]) {
              if (payload.asset[key][i][j] != null) { // Remove null values
                data.append(state.assetType + '[' + key + '][' + i + '][' + j + ']', payload.asset[key][i][j])
              }
            }
          } else {
            // If primative
            if (payload.asset[key][i] != null) { // Remove null values
              data.append(state.assetType + '[' + key + '][' + i + ']', payload.asset[key][i])
            }
          }
        }
      } else {
        if (payload.asset[key] != null) { // Remove null values
          data.append(state.assetType + '[' + key + ']', payload.asset[key])
        }
      }
    }

    let url
    if(payload.fullUrl) {
      url = payload.fullUrl      
    } else {
      url = payload.url + '/' + payload.asset.id + '.json'
    }

    return axios.put(url, data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  batchUpdate ({ commit, state }, payload) {
    // MAKE API CALL
    return axios.put(payload.url + '/batch_update', {
      topics: payload.assets
    })
  },
  delete ({ commit, state }, payload) {
    return axios.delete(payload.url + '/' + payload.record.id, { headers: {} })
  }
}

// mutations
const mutations = {
  setAsset (state, payload) {
    state.asset = payload.asset
  },
  setAssetType (state, payload) {
    state.assetType = payload.assetType
  },
  resetAsset (state) {
    state.asset = {}
  },
  setDeleteAsset (state, payload) {
    state.deleteAsset = payload.value
  },
  setDeletableAsset (state, payload) {
    state.deletableAsset = payload.asset
  },
  resetDeletableAsset (state) {
    state.deletableAsset = {}
  },
  addAssetAssociation (state, payload) {
    state.asset.asset_associations.push(payload.association)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
