<template>
  <div class="notification-feed">
    <div
      class="notification-icon-section"
      @click="showFeed = !showFeed"
      v-on-clickaway="() => showFeed = false"
    >
      <div
        v-if="unreadNotificationsCount > 0"
        class="notification-badge"
        >
          {{ unreadNotificationsCount }}
      </div>
      <bell-svg class="notification-bell" />
    </div>
    <div
      v-if="showFeed"
      class="notification-feed-container"
    >
      <div class="bar-top"></div>
      <div class="notification-bar">
        <div class="bar-header">Notifications</div>
        <div class="bar-actions">
          <a @click="markAllAsRead">Mark all as read</a>
        </div>
      </div>
      <div class="feed-list-container">
        <ul class="notfication-feed-list">
          <li v-if="notifications.length < 1" class="feed-item">
            <div class="feed-icon-section">
              <bell-svg class="notification-bell" />
            </div>
            <div class="feed-content-section">
              <p>There are no notifications.</p>
            </div>
          </li>
          <li
            v-else
            v-for="(notification, index) in notifications"
            :key="'notification' + '-' + index"
            :class="[{ unread: !notification.read }, 'feed-item']"
            @click="followLink(notification)"
          >
            <div class="feed-icon-section">
              <success-svg v-if="notification.status == 'success'" />
              <info-svg v-if="notification.status == 'info'" />
              <warning-svg v-if="notification.status == 'warning'" />
              <error-svg v-if="notification.status == 'error'" />
            </div>
            <div class="feed-content-section">
              <p>{{ notification.message }}</p>
              <div class="time-and-count">
                <small> {{ timeAgo(notification.created_at) }}</small>
              </div>
            </div>
          </li>
        </ul>
        <button class="hlt-button button secondary load-more" @click.stop="loadMoreNotifications">Load More</button>
      </div>
    </div>
    <error-modal />
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import date from '../../mixins/date'
import { mapGetters, mapActions } from 'vuex'
import ErrorModal from './ErrorModal'

import ErrorSvg from '@/assets/images/icons/alert-triangle-error.svg'
import WarningSvg from '@/assets/images/icons/alert-triangle-warning.svg'
import InfoSvg from '@/assets/images/icons/info-circle.svg'
import SuccessSvg from '@/assets/images/icons/check-circle-solid.svg'
import BellSvg  from '@/assets/images/icons/bell-solid.svg'

export default {
  components: {
    ErrorModal,
    ErrorSvg,
    InfoSvg,
    WarningSvg,
    SuccessSvg,
    BellSvg
  },
  mixins: [
    clickaway,
    date
  ],
  data () {
    return {
      showFeed: false
    }
  },
  computed: {
    ...mapGetters([
      'notifications',
      'unreadNotificationsCount'
    ])
  },
  async created () {
    await this.getNotifications()
  },
  methods: {
    ...mapActions([
      'getNotifications',
      'markAllAsRead',
      'markNotificationAsRead',
      'loadMoreNotifications'
    ]),
    followLink (notification) {
      if (notification.status === 'success') {
        this.modifyNotification(this.followRouterLink, notification)
      } else if (notification.status == 'error') {
        this.modifyNotification(this.showModal, notification)
      } else if (((notification.status == 'info') || (notification.status == 'warning')) &&
                  notification?.data?.link) {
        this.modifyNotification(this.followRouterLink, notification)
      }
    },
    modifyNotification (func, notification) {
      if (notification.read) {
        func(notification)
      } else {
        this.markNotificationAsRead(notification.id).then(() => {
          func(notification)
        })
      }
    },
    followRouterLink (data) {
      const link = this.generateLink(data)
      const currentRouteObj = {
        name: this.$router.currentRoute.name
      }

      if (link.params) {
        currentRouteObj.params = this.$router.currentRoute.params
      }

      if (link.query) {
        currentRouteObj.query = this.$router.currentRoute.query
      }

      if (!this._.isEqual(currentRouteObj, link)) {
        this.$router.push(link)
      }
    },
    showModal (data) {
      this.$modal.show('global-error-modal', {
        notification: data
      })
    },
    generateLink (notification) {
      // Read comments for clarity
      const name = notification?.data?.link?.type
      if (notification?.data?.link?.action === 'index') {
        if (notification.data.link.ids) { // Visit the index to search by notification ID
          return {
            name: name,
            query: {
              searchFilters: JSON.stringify({
                notificationId: notification.id.toString()
              })
            }
          }
        } else { // Just visit the index
          return {
            name: name
          }
        }
      } else if (notification?.data?.link?.action === 'edit') { // Visit edit view
        return {
          name: name,
          params: {
            id: notification.data.link.id.toString()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-feed {
  position: relative;
  right: 0;
  margin-left: 25px;
  .notification-icon-section {
    padding: 3px;
    &:hover {
      cursor: pointer;
    }
    .notification-badge {
      position: absolute;
      top: 0;
      right: 3px;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background: $red-1;
      z-index: 999;
      font-size: 11px;
      color: $white-1;
      font-weight: 700;
      text-align: center;
      padding: 0 3px;
    }
    .notification-bell {
      position: relative;
      height: 22px;
      width: 22px;
      margin-right: 5px;
      max-width: unset;
    }
  }
  .notification-feed-container {
    background: $space-gray-20;
    border: 1px solid rgba($space-gray, .25);
    position: absolute;
    width: 375px;
    overflow-y: visible;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 3px 8px rgba(0,0,0,.25);
    top: 40px;
    right: 0;
    z-index: 9999;
  }
  .bar-top {
    position: absolute;
    height: 11px;
    width: 20px;
    top: -11px;
    right: 7px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid $cold-gray;
  }
  .notification-bar {
    padding: 3px 10px;
    height: 25px;
    background: $cold-gray;
    font-size: 13px;
    font-weight: 600;
    color: $white-1;
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid rgba($space-gray, .25);
    a {
      color: $white-1;
      &:hover {
        color: $space-gray-30;
      }
    }
  }
  .feed-list-container {
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ul {
      overflow-y: scroll;
    }
    button {
      font-size: .9em;
    }
  }
  .notfication-feed-list {
    list-style: none;
    margin: 0;
    .feed-content-section {
      width: 100%;
      margin-right: 30px;
      p {
        margin-bottom: .5rem;
      }
    }
    .feed-item {
      width: 100%;
      display: flex;
      background: $cloud-white;
      font-size: 13px;
      padding: 10px;
      border-bottom: 1px solid rgba($space-gray, .25);
      &:hover {
        background: $space-gray-30;
        cursor: pointer;
      }
      &.unread {
        background: $space-gray-20;
        color: $space-black;
        font-weight: 700;
      }
    }
    .feed-icon-section {
      background: transparent;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      align-content: center;
      align-items: center;
      align-self: center;
      margin-right: 12px;
      svg {
        height: 16px;
      }
    }
    .time-and-count {
      color: $space-gray;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
