<template>
  <div class="recent-activity">
    <h3>Low PB Values</h3>
    <div class="activity-table">
      <div class="result headers">
        <div class="item-id">
          Item ID
        </div>
        <div class="app">
          App
        </div>
        <div class="pb">
          PB
        </div>
        <div class="date">
          Last Modified
        </div>
      </div>
      <div
        v-for="result in results"
        :id="result.id"
        :key="result.id"
        :result="result"
        class="result"
      >
        <div class="item-id">
          <a :href="editUrl(result)">{{ result.flashcard_id }}</a>
        </div>
        <div class="app">
          {{ result.app_name }}
        </div>
        <div class="pb">
          {{ result.pb.toFixed(3) }}
        </div>
        <div class="date">
          {{ prettyDate(result.updated_at) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import railsRoutes from '../../store/rails-routes'
import date from '../../mixins/date'

export default {
  mixins: [date],
  data () {
    return {
      results: []
    }
  },
  created () {
    this.fetchPBValues()
  },
  methods: {
    editUrl (obj) {
      return '/items/' + obj.flashcard_id + '/edit'
    },
    fetchPBValues () {
      axios.get(railsRoutes.api_path({ type: 'pb_values', action: 'low_values' })).then(response => {
        this.results = response.data.records
      }).catch(error => {
        console.error(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.recent-activity {
  margin: 30px;
  margin-top: 100px;
  width: 600px;

  .activity-table {
    border: 1px solid $grey-3;
  }

  .headers {
    background-color: $white-1;
    font-weight: bold;
  }
  .item-id {
    width: 150px;
  }
  .app {
    width: 250px;
  }
  .date {
    width: 250px;
  }

  .result {
    display: flex;
    border-bottom: 1px solid $grey-3;

    div {
      padding: 10px;
      font-size: .875rem;
    }
    &:nth-child(odd) {
      background-color: $white-1;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
