import axios from 'axios'
import Vue from 'vue'
import railsRoutes from '../../store/rails-routes'
import orderBy from 'lodash/orderBy'

// Consider namespacing this
// Then I can just create an entirely new instance of the module when
// doing the hierarchy search for the child assets without that messing
// up the main top level asset search results

// Question: Will the instance be disposable? Can (or even should) I create a
// new instance every time I need to make an API call (the user selects an asset)?
// Would it be more efficient to just make a generic util type function that returns
// the results instead?

// initial state
const state = {
  contentSources: [],
  verticals: [],
  searchResults: {},
  searchUrl: '',
  searchAssetType: '',
  searchPage: 1,
  searchTotal: 0,
  searchKeys: [],
  selectAll: false,
  searchIsActive: false,
  searchedWithKeys: false,
  keysHaveBeenModified: false,
  searchOrder: [['id'], ['asc']]
}

// getters
const getters = {
  contentSources (state) {
    return state.contentSources
  },
  verticals (state) {
    return state.verticals
  },
  keysHaveBeenModified (state) {
    return state.keysHaveBeenModified
  },
  searchIsActive (state) {
    return state.searchIsActive
  },
  searchResults (state) {
    return orderBy(state.searchResults, ...state.searchOrder)
  },
  searchedWithKeys (state) {
    return state.searchedWithKeys
  },
  searchUrl (state) {
    return state.searchUrl
  },
  searchAssetType (state) {
    return state.searchAssetType
  },
  searchPage (state) {
    return state.searchPage
  },
  searchTotal (state) {
    return state.searchTotal
  },
  searchKeys (state) {
    return state.searchKeys
  },
  selectedSearchKeys (state) {
    return state.searchKeys.filter(key => key.selected)
  },
  selectAll (state) {
    return state.selectAll
  },
  selectedSearchResults (state) {
    let output = []
    for (let k in state.searchResults) {
      if (state.searchResults[k].selected) {
        output.push(state.searchResults[k])
      }
    }
    return output
  },
  unselectedSearchResults (state) {
    let output = []
    for (let k in state.searchResults) {
      if (state.searchResults[k].selected == false) {
        output.push(state.searchResults[k])
      }
    }
    return output
  }
}

// actions
const actions = {
  searching ({ dispatch, commit }) {
    commit('toggleSearching')

    // If saved search exists && saved search asset type == current asset type
    // Repopulate the search keys from the saved search keys

    const savedSearchKeys = JSON.parse(window.$cookies.get('savedSearchKeys'))
    const savedSearchAssetType = window.$cookies.get('savedSearchAssetType')

    if (this.getters.searchAssetType !== 'asset_assignments' && savedSearchAssetType == this.getters.searchAssetType) {
      for (const i in savedSearchKeys) {
        const searchKey = savedSearchKeys[i]

        commit('selectSearchKey', { key: searchKey.key })
        commit('updateSearchKeyValue', {
          key: searchKey.key,
          value: searchKey.value,
          objValue: searchKey.objValue
        })
      }
    } else {
      window.$cookies.set(
        'savedSearchKeys',
        JSON.stringify(this.getters.selectedSearchKeys),
        null,
        null,
        null,
        null,
        'Lax'
      )
      window.$cookies.set(
        'savedSearchAssetType',
        this.getters.searchAssetType,
        null,
        null,
        null,
        null,
        'Lax'
      )
    }

    return dispatch('getSearchResults', this.getters.selectedSearchKeys)
      .then(response => {
        commit('setSearchedWithKeys', { keys: this.getters.selectedSearchKeys })
        commit('toggleSearching')
        commit('setSelectAll', { value: false })
        commit('processSearchResults', {
          searchResults: response.data.records.map((res) => { res.selected = this.getters.selectAll; return res }),
          searchTotal: response.data.meta.total
        })
      })
  },
  getSearchResults (context, keys) {
    let params = {
      page: this.getters.searchPage,
      m: this.getters.searchAssetType
    }

    for (const i in keys) {
      let key = keys[i]
      params[key.key] = key.value
    }
    return axios.get(this.getters.searchUrl + '.json', {
      params: params
    })
  },
  getContentSources ({ commit }) {
    return axios
      .get(railsRoutes.api_path({ type: 'content_sources' }))
      .then((response) => {
        commit('setContentSources', response.data.records)
      })
  },
  getVerticals ({ commit, state }) {
    return axios
      .get(railsRoutes.api_path({ type: 'verticals' }))
      .then((response) => {
        commit('setVerticals', response.data.records)
      })
  }
}

// mutations
const mutations = {
  toggleSearching (state, payload) {
    state.searchIsActive = !state.searchIsActive
  },
  setSearchAssetType (state, payload) {
    state.searchAssetType = payload.assetType
  },
  setContentSources (state, payload) {
    state.contentSources = [...payload]
  },
  setVerticals (state, payload) {
    state.verticals = [...payload]
  },
  setSearchUrl (state, payload) {
    state.searchUrl = payload.url
  },
  resetSearchResults (state) {
    state.searchResults = {}
    state.searchPage = 1
  },
  setSearchOrder (state, payload) {
    state.searchOrder = payload.searchOrder
  },
  setSearchedWithKeys (state, payload) {
    let keyCount = 0
    const selectedSearchKeys = payload.keys

    Object.keys(selectedSearchKeys).forEach(index => {
      const searchKeyObject = selectedSearchKeys[index]

      if (searchKeyObject.value.length != 0) {
        keyCount++
      }
    })
    state.searchedWithKeys = keyCount > 0
  },
  incrementSearchPage (state) {
    state.searchPage = state.searchPage + 1
  },
  processSearchResults (state, payload) {
    state.keysHaveBeenModified = false

    for (const result of payload.searchResults) {
      Vue.set(state.searchResults, result.id, result)
    }
    state.searchTotal = payload.searchTotal
  },
  setSearchKeys (state, payload) {
    state.searchKeys = payload.keys
  },
  selectSearchKey (state, payload) {
    state.keysHaveBeenModified = true
    Vue.set(state.searchKeys.filter(k => k.key == payload.key)[0], 'selected', true)
    Vue.set(state.searchKeys.filter(k => k.key == payload.key)[0], 'value', '')
    Vue.set(state.searchKeys.filter(k => k.key == payload.key)[0], 'objValue', null)
  },
  removeSelectedSearchKey (state, payload) {
    state.keysHaveBeenModified = true
    Vue.set(state.searchKeys.filter(k => k.key == payload.key)[0], 'selected', false)
    Vue.set(state.searchKeys.filter(k => k.key == payload.key)[0], 'value', '')
    Vue.set(state.searchKeys.filter(k => k.key == payload.key)[0], 'objValue', null)
  },
  updateSearchKeyValue (state, payload) {
    state.keysHaveBeenModified = true
    Vue.set(state.searchKeys.filter(k => k.key == payload.key)[0], 'value', payload.value)
    Vue.set(state.searchKeys.filter(k => k.key == payload.key)[0], 'objValue', payload.objValue)
  },
  setSelectAll (state, payload) {
    state.selectAll = payload.value
    for (const i in state.searchResults) {
      const result = state.searchResults[i]
      result.selected = state.selectAll
      Vue.set(state.searchResults, i, result)
    }
  },
  searchKeysHaveBeenModified (state) {
    state.keysHaveBeenModified = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
