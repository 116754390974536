<template>
  <div class="app" v-if="finishedLoading">
    <secured-app v-if="authenticated" />
    <insecure-app v-else />
    <hlt-notifications />
    <vue-confirm-dialog />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SecuredApp from './SecuredApp'
import InsecureApp from './InsecureApp'
import HLTNotifications from './shared/Notifications'

export default {
  components: {
    SecuredApp,
    InsecureApp,
    'hlt-notifications': HLTNotifications
  },
  data () {
    return {
      finishedLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'authenticated'
    ])
  },
  created () {
    this.axiosInit()
    this.authCheck().then(() => {
      this.finishedLoading = true
    })
  },
  methods: {
    ...mapActions([
      'authCheck',
      'axiosInit'
    ])
  }
}
</script>

<style lang="scss" scoped>
.app {
  height: 100%;
}
</style>
