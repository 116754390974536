<template>
  <modal
    name="create-lms-user-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal create-lms-user-modal"
    width="30%"
  >
    <div class="vue-modal-inner">
      <div class="vue-modal-topbar">
        <h4>Create new user</h4>
      </div>
      <div class="modal-container">
        <div>
          <label>First Name</label>
          <input
            v-model="firstName"
            name="first-name"
            class="hlt-input"
            placeholder="Enter first name"
          />
        </div>
        <div>
          <label>Last Name</label>
          <input
            v-model="lastName"
            name="last-name"
            class="hlt-input"
            placeholder="Enter last name"
          />
        </div>
        <div>
          <label>Email</label>
          <input
            v-model="email"
            name="email"
            class="hlt-input"
            placeholder="Enter email"
          />
        </div>
      </div>

      <div class="vue-modal-bottombar modal-buttons">
        <button
          class="button solid-green-button modal-button"
          @click.prevent="createUser()"
        >
          Create
        </button>
        <button
          class="button modal-button"
          @click="cancelCreateUser()"
        >
          Cancel
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'

export default {
  data () {
    return {
      firstName: null,
      lastName: null,
      email: null
    }
  },
  methods: {
    ...mapActions([
      'edit',
      'create'
    ]),
    ...mapMutations([
      'resetErrors'
    ]),
    createUser () {
      this.create(
        {
          asset: {
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName
          },
          url: railsRoutes.api_path({ type: 'admin_users' })
        }
      ).then(response => {
        let record = response.data.admin_user
        this.$modal.hide('create-lms-user-modal')

        this.edit(record)
        this.resetErrors()
        this.$router.push({ name: 'admin-lms-user', params: { id: record.id } })
        this.$notify({ title: 'User created and password reset instructions sent', type: 'success' })
      }).catch(error => {
        console.error(error)
        this.$notify({ title: 'Error Creating User', type: 'error' })
      })
    },
    cancelCreateUser () {
      this.email = null
      this.firstName = null
      this.lastName = null
      this.$modal.hide('create-lms-user-modal')
    }
  }
}
</script>


<style lang="scss" scoped>
.create-lms-user-modal {
  .modal-container  {
    padding: 10px 20px 40px 20px;
  }
}
</style>
