<template>
  <div class="hlt-multiselect">
    <multiselect
      ref="multi"
      v-bind="$attrs"
      :track-by="trackBy"
      :label="label"
      v-on="$listeners"
      @open="openHandler"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    trackBy: {
      type: String
    },
    label: {
      type: String
    }
  },
  methods: {
    openHandler () {
      this.$emit('open')
      const that = this.$refs.multi
      let value = JSON.parse(JSON.stringify(that.value))
      if (that && that.trackBy) {
        if (typeof that.value === 'object' && that.trackBy) {
          value = that.multiple ? that.value[0] : that.value
          if (!value) {
            return
          }
          that.pointer = that.filteredOptions.findIndex(opt => opt && (opt[that.trackBy] === value[that.trackBy]))
        } else {
          that.pointer = that.filteredOptions.indexOf(value)
        }
      }
      if (that.$refs.list) {
        that.$nextTick(() => {
          that.$refs.list.scrollTop = that.pointerPosition
        })
      }
    }
  }
}
</script>

<style lang="scss">
.hlt-multiselect {
  .multiselect {
    // changed for the dropdown background
    background: transparent;
    padding-bottom: 0;
    margin-bottom: 0;
    width: 100%;
    min-height: 35px;
    border: 1px solid $grey-3;
    border-radius: 5px;
    .multiselect__tag {
      background: rgba($blue-4, 0.4);
      color: rgba($blue-1, 0.9);
    }
    .multiselect__select {
      border: 0px;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 5px;
    }
    .multiselect__single {
      padding-left: 6px;
      margin-top: 7px;
      background: transparent !important;
    }
    .multiselect__input {
      background: transparent;
      border: 0;
      height: 40px;
      margin-top: 0;
      width: 100%;
    }
    .multiselect__tags-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-top: 6px;
    }
    .multiselect__content-wrapper {
      margin-top: .35rem;
      width: fit-content;
      min-width: 250px;
    }
    .multiselect__tags {
      display: flex;
      align-self: center;
      border-radius: 5px;
      border-radius: 5px;
      .multiselect__input {
        background: transparent;
        border: 0;
        box-shadow: none;
        width: 100%;
      }
    }
    .multiselect__tag-icon:hover {
      background: none;
    }
    .multiselect__option--selected.multiselect__option--highlight {
      background: $blue-3;
      color: #fff;
    }
    .multiselect__option.multiselect__option--highlight {
      background: $blue-4;
      color: #fff;
    }
    .multiselect__placeholder {
      padding: 3px 6px 0;
      margin-bottom: 0px;
      font-size: .95rem;
    }
  }
}
</style>
