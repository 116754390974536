import Vue from 'vue'
import axios from 'axios'

Object.defineProperties(Vue.prototype, {
  axios: {
    get () {
      return axios
    }
  }
})
