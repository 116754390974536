import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'

import search from './modules/search'
import crud from './modules/crud'
import appHierarchy from './modules/app_hierarchy'
import ajax from './modules/ajax'
import globals from './modules/globals'
import assetRoutes from './modules/asset-routes'
import settings from './modules/settings'
import discussions from './modules/discussions'
import assignments from './modules/models/assignments'
import topic from './modules/models/topic'
import products from './modules/models/products'
import notification from './modules/models/notification'
import userClassifications from './modules/models/user_classifications'
import auth from './modules/auth'
import axios from './modules/axios'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  actions,
  plugins: [],
  modules: {
    search,
    crud,
    appHierarchy,
    topic,
    globals,
    assetRoutes,
    settings,
    discussions,
    assignments,
    products,
    ajax,
    notification,
    userClassifications,
    auth,
    axios
  }
})
