<template>
  <modal
    name="create-item-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal create-item-modal-section"
    width="45%"
  >
    <div class="vue-modal-inner">
      <div class="vue-modal-topbar">
        <h4>Create an Item</h4>
      </div>
      <div class="modal-container">
        <div>
          <select
            v-model="selectedItemType"
            name="item-type"
            class="item-type-selection"
          >
            <option value="null">
              Select Item Type
            </option>
            <option
              v-for="(itemType, index) in itemTypes"
              :key="index"
              :value="itemType"
            >
              {{ itemType }}
            </option>
          </select>
        </div>
        <div class="new-tab-checkbox">
          <input
            id="checkbox"
            v-model="openItemInTab"
            type="checkbox"
          >
          <label
            class="checkbox-label"
            for="checkbox"
          >Open in new tab?</label>
        </div>
      </div>

      <div class="vue-modal-bottombar modal-buttons">
        <button
          class="button solid-green-button modal-button"
          @click.prevent="createItem()"
        >
          Create
        </button>
        <button
          class="button modal-button"
          @click="cancelCreateItem()"
        >
          Cancel
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'

export default {
  data () {
    return {
      openItemInTab: false,
      selectedItemType: null,
      itemTypes: [
        'Blank Text Completion',
        'Bowtie',
        'Cloze / Dropdown',
        'Date Picker',
        'Drag and Drop',
        'Flipcard',
        'Fractional Numeric Entry',
        'Highlight',
        'Hotspot',
        'Matrix Grid',
        'Multiple Choice',
        'Numeric Entry',
        'Ordered Response',
        'SATA'
      ]
    }
  },
  methods: {
    ...mapActions([
      'edit',
      'create'
    ]),
    ...mapMutations([
      'resetErrors'
    ]),
    createItem () {
      this.create({ asset: {
        item_type: this.selectedItemType
      },
      url: railsRoutes.api_path({ type: 'items' }) }).then(response => {
        let record = response.data.records[0]
        if (this.$modal) {
          this.$modal.hide('create-item-modal')
        }

        if (this.openItemInTab) {
          if (this.$router) {
            let newItem = this.$router.resolve({ path: `/items/${record.id}/edit` })
            window.open(newItem.href, '_blank')
          }
        } else {
          this.edit(record)
          this.resetErrors()
          if (this.$router) {
            this.$router.push({ path: `/items/${record.id}/edit` })
          }
        }

        this.$notify({ title: 'Item Created', type: 'success' })
      }).catch(error => {
        console.error(error)
        this.$notify({ title: 'Error Creating Item', type: 'error' })
      })
    },
    cancelCreateItem () {
      if (this.$modal) {
        this.$modal.hide('create-item-modal')
      }
    }
  }
}
</script>
