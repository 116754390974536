export default {
  cms_path: (params) => {
    if (params.id) {
      return `/${params.type}/${params.id}/edit`
    } else {
      return `/${params.type}`
    }
  },

  api_path: (params) => {
    // TODO: Refactor this some so its clearner
    if (params.id) {
      if (params.action) {
        return `/api/cms/${params.type}/${params.id}/${params.action}`
      } else {
        return `/api/cms/${params.type}/${params.id}`
      }
    } else {
      if (params.action) {
        return `/api/cms/${params.type}/${params.action}`
      } else {
        return `/api/cms/${params.type}`
      }
    }
  }
}
