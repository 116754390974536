<template>
  <modal
    name="create-catalog-page-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal create-item-modal-section"
    width="45%"
  >
    <div class="vue-modal-inner">
      <div class="vue-modal-topbar">
        <h4>Create a Catalog Page</h4>
      </div>
      <div class="modal-container">
        <div>
          <hlt-multiselect
            ref="multiselect"
            v-model="selectedApp"
            :options="currentUser.apps"
            :multiple="false"
            :options-limit="5"
            :show-labels="false"
            label="internal_name"
            class="product-select"
            track-by="id"
            placeholder="Select a product"
          />
        </div>
        <div class="new-tab-checkbox">
          <input
            id="checkbox"
            v-model="openItemInTab"
            type="checkbox"
          >
          <label
            class="checkbox-label"
            for="checkbox"
          >Open in new tab?</label>
        </div>
      </div>

      <div class="vue-modal-bottombar modal-buttons">
        <button
          class="button solid-green-button modal-button"
          @click.prevent="createCatalogPage()"
        >
          Create
        </button>
        <button
          class="button modal-button"
          @click="cancelCreateCatalogPage()"
        >
          Cancel
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import HLTMultiselect from '../../shared/VueMultiSelect'

export default {
  components: {
    'hlt-multiselect': HLTMultiselect
  },
  data () {
    return {
      openItemInTab: false,
      selectedApp: null,
    }
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },
  methods: {
    ...mapActions([
      'edit',
      'create'
    ]),
    ...mapMutations([
      'resetErrors'
    ]),
    createCatalogPage () {
      if(this.selectedApp) {
        this.create({ asset: {
          app_id: this.selectedApp?.id
        },
        url: railsRoutes.api_path({ type: 'catalog_pages' }) }).then(response => {
          var record = response.data.records[0]
          this.$modal.hide('create-catalog-page-modal')

          if (this.openItemInTab) {
            let newItem = this.$router.resolve({ path: `/catalog_pages/${record.id}/edit` })
            if(newItem) {
              window.open(newItem.href, '_blank')
            }
          } else {
            this.edit(record)
            this.resetErrors()
            this.$router.push({ path: `/catalog_pages/${record.id}/edit` })
          }

          this.$notify({ title: 'Catalog Page Created', type: 'success' })
        }).catch(error => {
          console.error(error)
          this.$notify({ title: 'Error Creating Catalog Page', type: 'error' })
        })
      }
    },
    cancelCreateCatalogPage () {
      this.$modal.hide('create-catalog-page-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  height: 200px;
  width: 80%;
}
</style>