<template>
  <div class="profile-section-container">
    <div
      @click="showProfile = !showProfile"
      v-on-clickaway="() => showProfile = false"
    >
      <img class="user-avatar-image" :src="currentUser.profile_photo_url" v-if="currentUser.profile_photo_url" />
      <div class="user-avatar" v-else>
        {{ userInitials }}
      </div>
    </div>
    <div
      v-if="showProfile"
      class="profile-dropdown"
    >
      <div class="profile-container">
        <div class="profile-top"></div>
        <div class="user-profile-section">
          <p class="user-profile-name montserrat">Hi, {{ userName | truncate(20) }}</p>
        </div>
        <div class="profile-divider"></div>
        <ul class="profile-options">
          <li>
            <router-link
              :to="{ name: 'account' }"
            >
              <div class="list-left user-icon">
                <user-svg />
              </div>
              <div class="list-right">
                  Account
              </div>
            </router-link>
          </li>
          <li>
            <a :href="oldCMSURL">
              <div class="list-left">
                <old-cms-svg />
              </div>
              <div class="list-right">
                  Old CMS
              </div>
            </a>
          </li>
          <li>
            <a
              @click.stop="signOut"
              class="signout"
            >
              <div class="list-left">
                <signout-svg />
              </div>
              <div class="list-right">
                  Sign out
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import { mapActions, mapGetters } from 'vuex'
import OldCmsSvg from '@/assets/images/icons/address-book.svg'
import SignoutSvg from '@/assets/images/icons/sign-out.svg'
import UserSvg from '@/assets/images/icons/user.svg'

export default {
  components: {
    OldCmsSvg,
    SignoutSvg,
    UserSvg
  },
  mixins: [
    clickaway
  ],
  data() {
    return {
      showProfile: false
    }
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ]),
    userName () {
      return `${this.currentUser.first_name} ${this.currentUser.last_name}`
    },
    userInitials () {
      return this.currentUser.first_name?.charAt(0)
    },
    oldCMSURL () {
      const admin_user_token = window.$cookies.get('authentication_token')
      return process.env.VUE_APP_AA_URL + `?token=${admin_user_token}` + "&email=" + this.currentUser.email
    }
  },
  methods: {
    ...mapActions([
      'signOut'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .profile-section-container {
    margin-left: 20px;
  }
  .user-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    text-align: center;
    border: 2px solid $cloud-white;
    background: $cloud-white;
    cursor: pointer;

    line-height: 1;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    color: $space-black;
    font-size: 12px;
  }
  .user-avatar-image {
    margin: 4px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    background: $cloud-white;
    cursor: pointer;
    span {
      line-height: 1;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      position: relative;
      top: -4px;
      color: $space-black;
      font-size: 12px;
    }
  }

  .profile-dropdown {
    position: relative;
    .profile-divider {
      box-sizing: content-box;
      height: 0;
      overflow: visible;
      clear: both;
      max-width: 75rem;
      margin: .25rem auto .75rem;
      border-top: 0;
      border-right: 0;
      border-bottom: 1px solid $space-gray-20;
      border-left: 0;
    }
    .profile-container {
      position: absolute;
      top: 12px;
      right: 0;
      width: 200px;
      padding: 15px;
      background: $cloud-white;
      border-radius: 4px;
      box-shadow: 0 3px 8px rgba(0,0,0,.25);
      z-index: 9999;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      .profile-top {
        position: absolute;
        height: 11px;
        width: 20px;
        top: -11px;
        right: 7px;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 11px solid $cloud-white;
      }
      .user-profile-name {
        font-size: 14px;
        margin-bottom: .5rem;
      }
      .profile-options {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        list-style: none;
        margin-left: 0;
        li {
          font-size: 14px;
          height: 30px;
          svg {
            height: 16px;
            width: 16px;
          }
          a {
            display: flex;
            align-content: center;
            color: $space-gray;
            &:hover {
              color: $sky-blue;
              transition: .3s;
            }
            .list-left {
              flex: 1;
              display: flex;
              align-items: center;
            }
            .list-right {
              flex: 5;
            }
          }
        }
      }
    }
  }
</style>
