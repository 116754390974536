import axios from 'axios'
import railsRoutes from '../../rails-routes'
import _ from 'lodash'
import Vue from 'vue'
const state = {
  notifications: {},
  currentNotificationsPage: 1,
  unreadNotificationsCount: 0
}

const mutations = {
  addNotifications (state, payload) {
    payload.forEach((n) => {
      Vue.set(state.notifications, n.id, n)
    })
  },
  setUnreadNotificationsCount (state, payload) {
    state.unreadNotificationsCount = payload
  },
  setCurrentNotificationPage(state, payload) {
    state.currentNotificationsPage = payload
  },
  markAllAsRead(state) {
    state.unreadNotificationsCount = 0
    Object.values(state.notifications).forEach((n) => {
      let newRecord = n
      newRecord.read = true

      Vue.set(state.notifications, n.id, newRecord)
    })
  },
  markNotificationAsRead(state, payload) {
    state.unreadNotificationsCount -= 1
    const notification = state.notifications[payload]
    notification.read = true
  }
}

const getters = {
  notifications(state) {
    return _.orderBy(Object.values(state.notifications), ['created_at'], ['desc'])
  },
  unreadNotificationsCount(state) {
    return state.unreadNotificationsCount
  }
}

const actions = {
  getNotifications({ commit, state }) {
    return axios.get(railsRoutes.api_path({ type: 'cms_notifications' }) + `?page=${state.currentNotificationsPage}`)
      .then((response) => {
        commit('addNotifications', response.data.records)
        commit('setUnreadNotificationsCount', response.data.meta.unread_count)
      })
  },
  loadMoreNotifications({ commit, state, dispatch }) {
    commit('setCurrentNotificationPage', state.currentNotificationsPage + 1)
    dispatch('getNotifications')
  },
  markAllAsRead({ commit }) {
    return axios.put(railsRoutes.api_path({ type: 'cms_notifications', action: 'mark_all_as_read' }))
      .then(() => {
        commit('markAllAsRead')
      })
  },
  markNotificationAsRead({ commit }, id) {
    return axios.put(railsRoutes.api_path({ type: 'cms_notifications', id: id }), {
      cms_notification: {
        read: true
      }
    }).then(() => {
        commit('markNotificationAsRead', id)
      })
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
