<template>
  <modal
    name="global-error-modal"
    height="auto"
    :scrollable="true"
    class="vue-modal"
    width="60%"
    @before-open="beforeOpen"
    @before-close="saving = false"
  >
    <div class="vue-modal-inner" v-if="notification.id">
      <div class="vue-modal-topbar">
        <a
          class="back-arrow"
          @click="$modal.hide('global-error-modal')"
        >
          <back-svg />
        </a>
        <h1 class="notification-error-message">Error: {{ notification.message }} </h1>
      </div>

      <div class="modal-content">
        <p>The following problem occurred:</p>
        <p>ID: {{ notification.id }} - {{ notification.message }}</p>
        <p>Source: {{ notification.source }}</p>
        <p v-if="notification && notification.data && notification.data.errors.length > 0">Errors: {{ notification.data.errors.length }} </p>
        <ul>
          <li v-for="(error, index) in notification.data.errors" :key="'notification-error' + index">
            {{ error }}
          </li>
        </ul>
      </div>

      <div class="vue-modal-bottombar">
        <button
          class="hlt-button default"
          @click="$modal.hide('global-error-modal')"
        >
          OK
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import BackSvg from '@/assets/images/icons/back-arrow.svg'

export default {
  components: {
    BackSvg
  },
  data () {
    return {
      notification: {}
    }
  },
  methods: {
    beforeOpen (event) {
      if (event && event.params) {
        this.resetModal()

        if (event.params.notification) {
          this.notification = event.params.notification
        }
      }
    },
    resetModal () {
      this.notification = {}
    }
  }
}
</script>
