import axios from 'axios'
import railsRoutes from '../../store/rails-routes'

const state = {
  axios: null
}

const mutations = {
  configureAjax: function (state, instance) {
    state.axios = instance
  }
}

const getters = {}

const actions = {
  apiTopicsById ({ commit, state }, payload) {
    return axios.get(railsRoutes.api_path({ type: 'topics' }), {
      params: {
        ids: [payload.topicId]
      }
    })
  },

  apiTopicHierachyById ({ commit, state }, payload) {
    return axios.get(
      railsRoutes.api_path({ type: 'topics', id: payload.topicId })
    )
  },

  apiAttachmentsById ({ commit, state }, payload) {
    return axios.get(
      railsRoutes.api_path({ type: 'attachments', id: payload.attachmentId })
    )
  },

  apiItemsById ({ commit, state }, payload) {
    return axios.get(
      railsRoutes.api_path({ type: 'items', id: payload.itemId })
    )
  },
  apiGroupingsById ({ commit, state }, payload) {
    return axios.get(
      railsRoutes.api_path({ type: 'groupings', id: payload.groupingId })
    )
  },
  apiLearningModulesById ({ commit, state }, payload) {
    return axios.get(
      railsRoutes.api_path({
        type: 'learning_modules',
        id: payload.learningModuleId
      })
    )
  },
  apiDecksById ({ commit, state }, payload) {
    return axios.get(
      railsRoutes.api_path({ type: 'decks', id: payload.deckId })
    )
  },
  apiCatalogPageById ({ commit, state }, payload) {
    return axios.get(
      railsRoutes.api_path({ type: 'catalog_pages', id: payload.catalogPageId })
    )
  },
  apiCatalogById ({ commit, state }, payload) {
    return axios.get(
      railsRoutes.api_path({ type: 'catalogs', id: payload.catalogId })
    )
  },
  apiAdminUserById ({ commit, state }, payload) {
    return axios.get(
      railsRoutes.api_path({ type: 'admin_users', id: payload.id })
    )
  },
  
}

export default {
  state,
  getters,
  actions,
  mutations
}
