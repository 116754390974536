import axios from 'axios'
import NProgress from 'nprogress'

const state = {
}

const mutations = {
}

const getters = {}

const actions = {
  axiosInit ({ dispatch }) {
    // This redirects the user on a 401
    axios.interceptors.response.use(
      function (response) {
        NProgress.done()
        return response
      },
      function (error) {
        NProgress.done()
        if (error.response == undefined) {
          console.error(
            'ERROR FROM SERVER: Most likely server had a 500 response',
            error
          )
        } else {
          if (error.response.status === 401) {
            console.error('401 Error: ' + error)
            dispatch('signOut', null, { root: true })
          }
        }
        return Promise.reject(error)
      }
    )
    // For showing progress bar
    axios.interceptors.request.use((config) => {
      NProgress.start()
      return config
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
