<template>
  <div>
    <div class="login-form">
      <div class="email">
        <label for="email">Email Address</label>
        <input
          v-model="email"
          type="text"
          placeholder="email@hltcorp.com"
          class="hlt-input"
        >
      </div>
      <div class="password">
        <label for="password">Password</label>
        <input
          v-model="password"
          type="password"
          placeholder="type in your lovely password"
          class="hlt-input"
          @keypress="submitForm($event)"
        >
      </div>
      <error-message
        v-if="errors"
        message="Login Failed"
      />
      <div class="login-button">
        <button @click.stop="login">
          Sign In
        </button>
      </div>
    </div>
    <div class="forgot-password">
      Whoops. I forgot my password. <a @click="forgotPassword">Reset my password</a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import axios from 'axios'
import railsRoutes from '../../store/rails-routes.js'

export default {
  data () {
    return {
      email: null,
      password: null,
      errors: null
    }
  },
  methods: {
    ...mapMutations([
      'setAuthenticated',
      'setNeedsToResetPassword',
      'setForgotPassword'
    ]),
    ...mapActions([
      'setCookies',
      'getCurrentUser'
    ]),
    forgotPassword () {
      this.setForgotPassword(true)
      this.$router.push({ name: 'forgot_password' })
    },
    submitForm (evt) {
      if (evt.charCode === 13) {
        this.login()
      }
    },
    login () {
      if (this.email && this.password) {
        axios.post(railsRoutes.api_path({ type: 'sessions' }), { email: this.email, password: this.password }).then(response => {
          this.setCookies(response.data)
          if (response.data.needs_to_reset_password) {
            this.setAuthenticated(false)
            this.setNeedsToResetPassword(true)
            this.$router.push({ name: 'password_reset' })
          } else {
            this.setNeedsToResetPassword(false)
            this.getCurrentUser()
            this.$router.push({ name: 'dashboard' })
          }
        }).catch(error => {
          console.error(error)
          this.errors = true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-form {
  margin-top: 30px;
  padding: 30px;
  background: white;
  border: 1px solid $space-gray-20;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .email {
    margin-bottom: 20px;
  }
  label {
    text-align: left;
    font-weight: 700;
    padding-bottom: 5px;
    color: $space-gray;
  }
  input {
    border-radius: 4px;
    color: $blue-2;
    margin-bottom: 0px;
  }

  .login-button {
    margin-top: 25px;
    button {
      background-color: $sky-blue;
      border-radius: 4px;
      width: 100%;
      padding: 15px;
      color: white;
      font-weight: 700;
      letter-spacing: .03em;
      transition: .3s;
      cursor: pointer;
      &:hover {
        background-color: darken($sky-blue, 10%);
      }
    }
  }
}

.forgot-password {
  color: $space-gray;
  text-align: center;
  margin-top: 8px;
  a {
    color: $sky-blue;
  }
}
</style>
