import axios from 'axios'
import railsRoutes from '../../store/rails-routes'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import router from '../../router'

Vue.use(VueCookies)

const state = {
  currentUser: null,
  authenticated: null,
  needsToResetPassword: false,
  forgotPassword: false
}

const mutations = {
  setCurrentUser (state, payload) {
    // sorting the userAdmin apps, before mutating the state
    payload.currentUser.apps.sort((a, b) => {
      return (
        (a.internal_name > b.internal_name) -
        (a.internal_name < b.internal_name)
      )
    })
    state.currentUser = payload.currentUser
  },
  setAuthenticated(state, payload) {
    state.authenticated = payload
  },
  setNeedsToResetPassword(state, payload) {
    state.needsToResetPassword = payload
  },
  setForgotPassword(state, payload) {
    state.forgotPassword = payload
  }
}

const getters = {
  currentUser (state) {
    return state.currentUser
  },
  authenticated(state) {
    return state.authenticated
  },
  needsToResetPassword(state) {
    return state.needsToResetPassword
  },
  forgotPassword(state) {
    return state.forgotPassword
  },
  canAccessAssignments(state) {
    return state.currentUser.permissions.is_a_asset_assigner || state.currentUser.permissions.is_a_copy_editor || state.currentUser.permissions.is_a_copy_writer
  },
  canWriteToAsset(state, getters, rootState, rootGetters) {
    if (rootGetters.discussionMode) {
      return false
    }
    if (rootGetters.assignment) {
      return false
    }

    let aType = rootGetters.assetType
    if (aType === 'item') {
      aType = 'flashcard'
    }
    if (aType === 'admin_users') {
      aType = 'admin_user'
    }

    return (
      getters.hasAppPermission &&
      getters.currentUser.permissions[`can_write_${aType}s`]
    )
  },
  canDeleteAsset(state, getters, rootState, rootGetters) {
    let aType = rootGetters.assetType
    if (aType === 'item') {
      aType = 'flashcard'
    }
    return (
      getters.hasAppPermission &&
      getters.currentUser.permissions[`can_delete_${aType}s`]
    )
  },
  canWriteToProductAssociations(state, getters, rootState, rootGetters) {
    let aType = rootGetters.assetType
    return (
      getters.hasAppPermission &&
      getters.currentUser.permissions[
        `can_write_${aType}s_product_associations`
      ]
    )
  },
  hasAppPermission(state, getters, rootState, rootGetters) {
    if (getters.currentUser.is_admin) {
      return true
    }

    if (state.currentUser.apps) {
      let allowedApps = state.currentUser.apps.map((x) => x.id)
      let hasApps = rootGetters.apps.map((x) => x.id).filter((n) => n) // last filter to strip out nulls
      return hasApps.every((elem) => allowedApps.indexOf(elem) > -1)
    } else {
      return false
    }
  }
}

const actions = {
  setCookies({}, data) {
    Vue.$cookies.set(
      'authentication_email',
      data.email,
      '14d',
      '/',
      process.env.VUE_APP_COOKIE_DOMAIN,
      null,
      'Lax'
    )
    Vue.$cookies.set(
      'authentication_token',
      data.token,
      '14d',
      '/',
      process.env.VUE_APP_COOKIE_DOMAIN,
      null,
      'Lax'
    )
    axios.defaults.headers.common['X-AdminUser-Email'] = data.email
    axios.defaults.headers.common['X-AdminUser-Token'] = data.token
  },
  removeCookies() {
    const domain = process.env.VUE_APP_COOKIE_DOMAIN || 'localhost'
    Vue.$cookies.remove('authentication_email')
    Vue.$cookies.remove('authentication_token')
    Vue.$cookies.remove('authentication_email', '/', domain)
    Vue.$cookies.remove('authentication_token', '/', domain)
  },
  authCheck({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      const adminUserEmail = Vue.$cookies.get(
        'authentication_email',
        process.env.VUE_APP_COOKIE_DOMAIN
      )
      const adminUserToken = Vue.$cookies.get(
        'authentication_token',
        process.env.VUE_APP_COOKIE_DOMAIN
      )

      if (adminUserEmail && adminUserToken) {
        dispatch('getCurrentUser').then(() => {
          resolve()
        })
      } else {
        commit('setAuthenticated', false)
        commit('setNeedsToResetPassword', false)
        resolve()
      }
    }).catch((error) => {
      console.error(error)
      reject(error)
    })
  },
  signOut({ commit, dispatch }) {
    dispatch('removeCookies')
    commit('setNeedsToResetPassword', false)
    commit('setAuthenticated', false)
    router.push({ name: 'login' })
  },
  getCurrentUser({ commit, dispatch }) {
    return axios
      .get(
        railsRoutes.api_path({
          type: 'admin_users',
          action: 'permissions'
        }),
        {}
      )
      .then((response) => {
        commit('setCurrentUser', { currentUser: response.data.admin_user })
        dispatch('checkPermissions')
      })
      .catch((error) => {
        console.error(error)
        dispatch('signOut')
      })
  },
  checkPermissions({ dispatch, getters, commit }) {
    if (getters.currentUser.permissions.can_read_new_cms) {
      commit('setAuthenticated', true)
    }

    if (!getters.authenticated) {
      Vue.prototype.$notify({
        title: 'Invalid permissions',
        type: 'error'
      })
      dispatch('signOut')
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
