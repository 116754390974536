<template>
  <div>
    <nav-container>
    <template v-slot:headerName>
      <div class="title">
        Dashboard
      </div>
    </template>
  </nav-container>
    <low-pb-values v-if="currentUser.permissions.can_read_cms_low_pb_values" />
    <!-- <recent-activity v-if="currentUser.permissions.can_read_cms_recent_activity" /> -->
  </div>
</template>

<script>
import NavContainer from './shared/NavContainer'
// import RecentActivity from './workspaces/RecentActivity'
import LowPbValues from './workspaces/LowPbValues'

import { mapGetters } from 'vuex'

export default {
  components: {
    NavContainer,
    // RecentActivity,
    LowPbValues
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  }
}
</script>
