import Vue from 'vue'
import Router from 'vue-router'
import DashboardComponent from './components/Dashboard.vue'
Vue.use(Router)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      name: 'dashboard',
      path: '/',
      component: DashboardComponent
    },
    {
      name: 'login',
      path: '/login'
    },
    {
      name: 'password_reset',
      path: '/password_reset'
    },
    {
      name: 'password_reset_with_token',
      path: '/password_reset_with_token'
    },
    {
      name: 'forgot_password',
      path: '/forgot_password'
    },
    {
      name: 'topics',
      path: '/topics',
      component: () => import('./components/assets/shared/Index.vue')
    },
    {
      name: 'topic',
      path: '/topics/:id/edit',
      component: () => import('./components/assets/shared/Edit.vue')
    },
    {
      name: 'attachments',
      path: '/attachments',
      component: () => import('./components/assets/shared/Index.vue')
    },
    {
      name: 'attachment',
      path: '/attachments/:id/edit',
      component: () => import('./components/assets/shared/Edit.vue')
    },
    {
      name: 'groupings',
      path: '/groupings',
      component: () => import('./components/assets/shared/Index.vue')
    },
    {
      name: 'grouping',
      path: '/groupings/:id/edit',
      component: () => import('./components/assets/shared/Edit.vue')
    },
    {
      name: 'learning_modules',
      path: '/learning_modules',
      component: () => import('./components/assets/shared/Index.vue')
    },
    {
      name: 'learning_module',
      path: '/learning_modules/:id/edit',
      component: () => import('./components/assets/shared/Edit.vue')
    },
    {
      name: 'items',
      path: '/items',
      component: () => import('./components/assets/shared/Index.vue')
    },
    {
      name: 'item',
      path: '/items/:id/edit',
      component: () => import('./components/assets/shared/Edit.vue')
    },
    {
      name: 'themes',
      path: '/themes',
      component: () => import('./components/settings/shared/Index.vue')
    },
    {
      name: 'theme',
      path: '/themes/:id/edit',
      component: () => import('./components/settings/shared/Index.vue')
    },
    {
      name: 'roles',
      path: '/roles',
      component: () => import('./components/settings/shared/Index.vue')
    },
    {
      name: 'role',
      path: '/roles/:id/edit',
      component: () => import('./components/settings/shared/Index.vue')
    },
    {
      name: 'tags',
      path: '/tags',
      component: () => import('./components/settings/shared/Index.vue')
    },
    {
      name: 'content-sources',
      path: '/content-sources',
      component: () => import('./components/settings/shared/Index.vue')
    },
    {
      name: 'products',
      path: '/products',
      component: () => import('./components/settings/shared/Index.vue')
    },
    {
      name: 'product',
      path: '/products/:id/edit',
      component: () => import('./components/settings/shared/Edit.vue')
    },
    {
      name: 'history',
      path: '/history',
      component: () => import('./components/history/Search.vue')
    },
    {
      name: 'app-hierarchy',
      path: '/app_hierarchy',
      component: () =>
        import('./components/app_manager/shared/AppManagerContainer.vue')
    },
    {
      name: 'catalogs',
      path: '/catalogs',
      component: () =>
        import('./components/app_manager/shared/AppManagerContainer.vue')
    },
    {
      name: 'catalog',
      path: '/catalogs/:id/edit',
      component: () =>
        import('./components/app_manager/shared/AppManagerContainer.vue')
    },
    {
      name: 'catalog_pages',
      path: '/catalog_pages',
      component: () =>
        import('./components/app_manager/shared/AppManagerContainer.vue')
    },
    {
      name: 'catalog_page',
      path: '/catalog_pages/:id/edit',
      component: () =>
        import('./components/app_manager/shared/AppManagerContainer.vue')
    },
    {
      name: 'decks',
      path: '/decks',
      component: () =>
        import('./components/app_manager/shared/AppManagerContainer.vue')
    },
    {
      name: 'deck',
      path: '/decks/:id/edit',
      component: () =>
        import('./components/app_manager/shared/AppManagerContainer.vue')
    },
    {
      name: 'discussions',
      path: '/discussions',
      component: () => import('./components/publishing/shared/Index.vue')
    },
    {
      name: 'discussion',
      path: '/discussions/:id/thread',
      component: () =>
        import('./components/publishing/discussions/Discussions.vue')
    },
    {
      name: 'asset_assignments',
      path: '/asset_assignments',
      component: () => import('./components/publishing/shared/Index.vue')
    },
    {
      name: 'asset_assignment',
      path: '/asset_assignments/:id/review',
      component: () => import('./components/publishing/assignments/Review.vue'),
      props: true
    },
    {
      name: 'assignment_reports',
      path: '/assignment_reports',
      component: () => import('./components/reports/shared/Index.vue')
    },
    {
      name: 'account',
      path: '/account',
      component: () => import('./components/Account.vue')
    },
    {
      name: 'admin',
      path: '/admin',
      component: () => import('./components/admin/AdminContainer.vue')
    },
    {
      name: 'admin-lms-users',
      path: '/admin/lms-users',
      component: () => import('./components/admin/AdminContainer.vue')
    },
    {
      name: 'admin-lms-user',
      path: '/admin/lms-users/:id/edit',
      component: () => import('./components/admin/AdminContainer.vue')
    },
  ]
})
router.beforeEach((to, from, next) => {
  let pageTitle
  if (to.name) {
    pageTitle = to.name.replace(/[_-]/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  } else {
    pageTitle = ''
  }
  let pageId
  if (to.params.id) {
    pageId = to.params.id
  } else {
    pageId = ''
  }
  if (pageId) {
    document.title = `${pageTitle} - ID: ${pageId} - HLT CMS`
  } else {
    document.title = `${pageTitle} - HLT CMS`
  }
  next()
})
router.isRoute = (type) => {
  return (router.currentRoute.name === type) || (router.currentRoute.name === type + 's')
}
router.pluralName = () => {
  if(router.currentRoute.name.slice(-1) == 's') {
    return router.currentRoute.name
  } else {
    return router.currentRoute.name === type + 's'
  }
}
export default router
