import Vue from 'vue'

const namespaced = true

// initial state
const state = {
  appHierarchyColumns: [],
  // Example:
  // [ {
  //   parent: { id: 1, name: 'blah' },
  //   parentType: 'App',
  //   rows: [
  //     { rowType: 'NavigationGroup', rowObject: { id: 2, name: 'main nav group' }}
  //     { rowType: 'NavigationItem', rowObject: { id: 5, title: 'some nav item' }}
  //   ]
  // }]
  selectedApp: null,
  draftMode: true,
  adding: {},
  navigationIcons: [],
  navigationDestinations: []
}

// getters
const getters = {
  selectedApp (state) {
    return state.appHierarchyColumns[0].rows.filter(k => k.selected)[0].rowObject
  },
  appHierarchyColumns (state) {
    return state.appHierarchyColumns
  },

  adding (state) {
    return state.adding
  },
  navigationIcons (state) {
    return state.navigationIcons
  },
  navigationDestinations (state) {
    return state.navigationDestinations
  },
  nextToLastColumn (state) {
    if (state.appHierarchyColumns.length > 1) {
      return state.appHierarchyColumns[state.appHierarchyColumns.length - 2]
    } else {
      return null
    }
  },
  lastColumn (state) {
    if (state.appHierarchyColumns.length > 0) {
      return state.appHierarchyColumns[state.appHierarchyColumns.length - 1]
    } else {
      return null
    }
  },
  activeRow (state) {
    // if column is last column and active , if row
    if (getters.lastColumn(state) && getters.lastColumn(state).rows) {
      var lastColumnSelection = getters.lastColumn(state).rows.filter(o => o.selected)[0]
      if (lastColumnSelection) {
        return lastColumnSelection
      } else if (getters.nextToLastColumn(state)) { // check next to last column
        return getters.nextToLastColumn(state).rows.filter(o => o.selected)[0]
      }
    }
    return null
  }

}

// actions
const actions = {
}

// mutations
const mutations = {
  addHierarchyColumn (state, payload) {
    var newArray = state.appHierarchyColumns.slice(0, payload.columnIndex + 1)
    newArray.push({
      parent: payload.parent,
      parentType: payload.parentType,
      rows: payload.rows
    })
    state.appHierarchyColumns = newArray
  },
  clearHierarchyColumnsAfter (state, payload) {
    var newArray = state.appHierarchyColumns.slice(0, payload.columnIndex + 1)
    state.appHierarchyColumns = newArray
  },
  selectRow (state, payload) {
    var column = state.appHierarchyColumns[payload.columnIndex]

    for (var i in column.rows) {
      var row = column.rows[i]
      if (row == payload.row) {
        row.selected = true
      } else {
        row.selected = false
      }
      column.rows[i] = row
      Vue.set(column.rows[i], i, row)
    }
    Vue.set(state.appHierarchyColumns, payload.columnIndex, column)

    // Truncate the rest of the columns since they're now stale
    var newArray = state.appHierarchyColumns.slice(0, payload.columnIndex + 1)
    state.appHierarchyColumns = newArray
  },
  reselectActiveRow (state) {

  },
  setAdding (state, payload) {
    state.adding = payload.adding
  },
  setNavigationIcons (state, payload) {
    state.navigationIcons = payload.navigationIcons
  },
  setNavigationDestinations (state, payload) {
    state.navigationDestinations = payload.navigationDestinations
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
