<template>
  <notifications>
    <template
      slot="body"
      slot-scope="props"
    >
      <div class="notification-wrapper">
        <div
          class="notification hlt-notifications"
          :class="props.item.type"
        >
          <div class="notification-icon">
            <span
              v-if="props.item.type === 'info'"
              class="success-icon"
            >
              <info-svg />
            </span>
            <span
              v-if="props.item.type === 'success'"
              class="success-icon"
            >
              <success-svg />
            </span>
            <span
              v-if="props.item.type === 'warning'"
              class="warning-icon"
            >
              <warning-svg />
            </span>
            <span
              v-if="props.item.type === 'error'"
              class="error-icon"
            >
              <error-svg />
            </span>
          </div>
          <div class="notification-text">
            <div class="notification-title">
              {{ props.item.title }}
            </div>
            <div
              class="notification-content"
              v-html="props.item.text"
            />
          </div>
          <a
            class="close"
            @click="props.close"
          >
            <x-svg />
          </a>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import ErrorSvg from '@/assets/images/icons/times-octagon-light.svg'
import WarningSvg from '@/assets/images/icons/exclamation-triangle-light.svg'
import InfoSvg from '@/assets/images/icons/info-circle2.svg'
import SuccessSvg from '@/assets/images/icons/check-circle-light.svg'
import XSvg from '@/assets/images/icons/x.svg'

export default {
  components: {
    ErrorSvg,
    WarningSvg,
    InfoSvg,
    SuccessSvg,
    XSvg
  }
}
</script>

<style lang="scss">
.vue-notification-group {
  z-index: 99999 !important;
}

.hlt-notifications {
  margin: 20px 5px 5px 5px !important;
  min-height: 80px;
  padding: 10px;
  color: $cloud-white;
  font-size: .875rem;
  display: flex !important;
  align-content: space-between;
  border-left: 5px solid $sky-blue;
  .notification-title {
    color: $cloud-white;
  }
  .notification-content {
    color: $cloud-white;
  }
  .notification-icon {
    width: 30px;
    align-self: center;
    span {
      font-size: 16px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .notification-text {
    width: 225px;
    align-self: center;
    margin-left: 10px;
  }
  .close {
    display: block;
    height: 12px;
    width: 12px;
  }
  &.info {
    background: $space-gray;
    border-left-color: darken($sky-blue, 30%);
  }
  &.warning {
    background: $orange-1;
    border-left-color: darken($orange-1, 30%);
  }
  &.error {
    background: $red-1;
    border-left: 5px solid darken($red-1, 30%) !important;
  }
  &.success {
    background: $pastel-green;
    border-left-color: $apple-green;
  }
}
</style>
