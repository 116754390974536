<template>
  <div class="page">
    <div class="password-form-container">
      <div class="logo">
        <a href="/">
          <logo-svg />
        </a>
      </div>

      <div class="form-container">
        <password-reset v-if="showPasswordReset" @passwordReset="goToLogin" />
        <forgot-password v-else-if="showForgotPassword" />
        <login-form v-else />
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginForm from './authentication/LoginForm'
import PasswordReset from './authentication/PasswordReset'
import ForgotPassword from './authentication/ForgotPassword'
import LogoSvg from '@/assets/images/hlt-full-logo.svg'

export default {
  components: {
    LoginForm,
    PasswordReset,
    ForgotPassword,
    LogoSvg
  },
  data () {
    return {
      doneResetting: false
    }
  },
  computed: {
    ...mapGetters([
      'authenticated',
      'needsToResetPassword',
      'forgotPassword'
    ]),
    showPasswordReset () {
      return !this.doneResetting && (this.needsToResetPassword || this.$router.isRoute('password_reset_with_token') || this.$router.isRoute('password_reset'))
    },
    showForgotPassword () {
      return this.forgotPassword || this.$router.isRoute('forgot_password')
    }
  },
  methods: {
    goToLogin() {
      this.doneResetting = true
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $space-gray-30;
  height: 100%;

  .logo {
    text-align: center;
  }
}
</style>
