import store from '../../store'
const state = {
}

const mutations = {
}

const getters = {

  // The methods below are deprecated, please use the 'isRoute' getter instead
  isAttachmentRoute () {
    return (store.state.route.name === 'attachments') || (store.state.route.name === 'attachment')
  },
  isTopicRoute () {
    return (store.state.route.name === 'topics') || (store.state.route.name === 'topic')
  },
  isItemRoute () {
    return (store.state.route.name === 'items') || (store.state.route.name === 'item')
  },
  isGroupingRoute () {
    return (store.state.route.name === 'groupings') || (store.state.route.name === 'grouping')
  },
  isLearningModuleRoute () {
    return (store.state.route.name === 'learning_modules') || (store.state.route.name === 'learning_module')
  },
  isRoleRoute () {
    return (store.state.route.name === 'roles') || (store.state.route.name === 'role')
  },
  isThemeRoute () {
    return (store.state.route.name === 'themes') || (store.state.route.name === 'theme')
  },
  isTagRoute () {
    return (store.state.route.name === 'tags') || (store.state.route.name === 'tag')
  },
  isDeckRoute () {
    return (store.state.route.name === 'decks') || (store.state.route.name === 'deck')
  },
  isCatalogRoute () {
    return (store.state.route.name === 'catalogs') || (store.state.route.name === 'catalog')
  },
  isCatalogPageRoute () {
    return (store.state.route.name === 'catalog_pages') || (store.state.route.name === 'catalog_page')
  },
  isDiscussionRoute () {
    return (store.state.route.name === 'discussions') || (store.state.route.name === 'discussion')
  },
  isAppHierarchyRoute () {
    return (store.state.route.name === 'app-hierarchy')
  }
}

const actions = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
